import type { FC, ReactNode } from 'react';

type NavBarBackgroundProps = {
  isExpanded?: boolean;
  children: ReactNode;
};

const NavBarBackground: FC<NavBarBackgroundProps> = ({ isExpanded, children }) => {
  return (
    <div
      className={`w-full flex lg:relative items-center lg:transition-[width] lg:duration-500 justify-center bg-color-gray_light lg:bg-color-secondary shadow-[0_-5px_16px_rgba(0,0,0,0.2)] lg:shadow-none z-10 lg:pt-6 ${
        isExpanded ? 'lg:w-64' : 'lg:w-24'
      }`}
    >
      {children}
    </div>
  );
};

export default NavBarBackground;
