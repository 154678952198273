import { ISubCommerce } from '@interfaces/Payment';

export const defaultSubCommerce: ISubCommerce = {
  bankAccount: { account: '', type: 3 },
  lastName: '',
  address: {
    zipCode: '',
    colony: '',
    externalNumber: '',
    internalNumber: '',
    street: '',
    municipality: '',
    state: '',
  },
  product: { id: 181 },
  phoneExtension: '',
  phoneCityCode: '',
  accountStateFile: '',
  businessLogo: '',
  ineFile: '',
  mcc: '',
  birthDate: '',
  contractFile: '',
  rfc: '',
  phoneNumber: '',
  mothersName: '',
  addressIdFile: '',
  name: '',
  ineBackFile: '',
  middleName: '',
  taxType: '',
  cellPhone: '',
  email: '',
};

export const defaultPMAttributes: Pick<ISubCommerce, 'officialName' | 'legalRepresentative'> = {
  officialName: '',
  legalRepresentative: {
    name: '',
    middleName: '',
    firstName: '',
    mothersName: '',
    birthDate: '',
    rfc: '',
    email: '',
    phoneCityCode: '',
    phoneNumber: '',
    phoneExtension: '',
    cellPhone: '',
  },
};
