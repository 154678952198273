import { useCallback, useEffect, useState } from 'react';

const MEDIA_QUERY = 1024;

export const useDesktop = () => {
  const [isDesktop, setDesktop] = useState(false);

  const handleWindowResize = useCallback(() => {
    if (window.innerWidth < MEDIA_QUERY) setDesktop(false);
    else setDesktop(true);
  }, []);

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  return isDesktop;
};
