import ButtonAction from '@components/interface/ButtonAction';
import { Icon } from '@iconify/react';
import { useAvailableSubscriptions, useEnableTrial, useSubscription } from '../hooks/useSubscriptions';
import { useNavigate } from 'react-router-dom';
import { SUBSCRIPTION_TYPES_MAP } from '@utils/constants';

interface SubscriptionStatusBarProps {
  isMobile?: boolean;
  isSmall?: boolean;
}

export const SubscriptionStatusBar = ({ isMobile = false, isSmall = false }: SubscriptionStatusBarProps) => {
  const { isFreemium, isTrial, remainingTrialDays, isTrialAvailable, trialUsed } = useSubscription();
  const { getSubscriptionById } = useAvailableSubscriptions();
  const navigate = useNavigate();
  const { isEnablingTrial } = useEnableTrial();
  const isTrialOrTrialIsNotAvailable = isTrial || (isFreemium && !isTrialAvailable);

  const planLabelText = isTrial
    ? `TE QUEDA${remainingTrialDays === 1 ? '' : 'N'} ${remainingTrialDays} DÍA${remainingTrialDays === 1 ? '' : 'S'} DE TU PRUEBA GRATUITA`
    : isFreemium
      ? getSubscriptionById(
          SUBSCRIPTION_TYPES_MAP.FREEMIUM.key as keyof typeof SUBSCRIPTION_TYPES_MAP,
        )?.nombre?.toUpperCase() || 'VERSIÓN FREEMIUM'
      : '';

  const ctaText = isTrialOrTrialIsNotAvailable
    ? trialUsed && remainingTrialDays > 0
      ? 'CONTRATA TU PLAN y sigue creciendo tus ventas.'
      : '¡Tu prueba ha terminado! Contrata un plan y crece tus ventas'
    : 'Descubre los beneficios que PLICK tiene para ti';

  const buttonText = isTrialOrTrialIsNotAvailable ? 'VER PLANES' : isFreemium ? 'ACTIVAR PRUEBA GRATUITA' : '';
  const buttonIcon = isTrialOrTrialIsNotAvailable ? 'solar:tag-price-bold' : isFreemium ? 'fa-solid:medal' : '';

  return (
    <div
      className={`bg-color-primary text-color-white flex ${
        isMobile ? 'flex-col px-6' : `flex-row ${isSmall ? 'px-2' : 'px-7'}`
      } justify-between items-center`}
    >
      <div className={`flex items-center ${isMobile ? '' : 'mr-6'}`}>
        {!isMobile && <Icon icon="simple-line-icons:exclamation" width="20px" />}
        <p className={`font-semibold ${isMobile ? 'text-xs mt-2' : `ml-2 ${isSmall ? 'text-xs' : ''}`}`}>
          {planLabelText}
        </p>
      </div>
      <div className="flex items-center">
        {!isMobile && <p className={`mr-4 font-semibold text-end ${isSmall ? 'text-sm' : ''}`}>{ctaText}</p>}
        <div className="[&>button]:text-sm [&>button]:h-[33px] [&>button]:my-2 [&_svg]:w-[18px] [&_svg]:h-[18px]">
          <ButtonAction
            textButton={buttonText}
            icon={buttonIcon}
            colorBg="color-white"
            colorTxt="color-secondary"
            disable={isEnablingTrial}
            onClick={() =>
              isTrialOrTrialIsNotAvailable ? navigate('/mi-cuenta') : isFreemium ? navigate('/activar-trial') : null
            }
            hasBorder
          />
        </div>
      </div>
    </div>
  );
};
