import { Icon } from '@iconify/react';
import type { FC } from 'react';
import Tooltip from '@components/interface/Tooltip';

type NavBarToggleButtonProps = {
  isExpanded: boolean;
  onToggle(): void;
};

const NavBarToggleButton: FC<NavBarToggleButtonProps> = ({ isExpanded, onToggle }) => {
  return (
    <div
      onClick={onToggle}
      className="absolute z-10 flex items-center justify-center top-4 -right-4 p-1 bg-color-white border-2 text-color-secondary rounded-full hover:bg-color-secondary_light cursor-pointer"
    >
      <Tooltip label={isExpanded ? 'Contraer' : 'Expandir'}>
        <Icon icon="bxs:left-arrow" className={`text-xl transition-transform ${isExpanded ? '' : 'rotate-180'}`} />
      </Tooltip>
    </div>
  );
};

export default NavBarToggleButton;
