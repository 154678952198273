import LoadingPage from '@pages/LoadingPage';
import { Suspense, lazy } from 'react';
import { Navigate, Route, createRoutesFromElements } from 'react-router-dom';

const NonAuthLayout = lazy(() => import('@pages/Auth/components/layout/NonAuthLayout'));
const RequestNewPassword = lazy(() => import('@pages/Auth/RequestNewPassword'));
const Login = lazy(() => import('@pages/Auth/Login'));
const Registro = lazy(() => import('@pages/Auth/Registro'));
const ResetPassword = lazy(() => import('@pages/Auth/ResetPassword'));
const Terminos = lazy(() => import('@pages/Legal/Terminos'));

export const unauthenticatedRoutes = createRoutesFromElements(
  <Route
    path="/"
    element={
      <Suspense fallback={<LoadingPage />}>
        <NonAuthLayout />
      </Suspense>
    }
  >
    <Route index element={<Navigate to="login" />} />
    <Route
      path="login"
      element={
        <Suspense fallback={<LoadingPage />}>
          <Login />
        </Suspense>
      }
    >
      <Route
        path="recuperar-contrasena"
        element={
          <Suspense fallback={<LoadingPage />}>
            <RequestNewPassword />
          </Suspense>
        }
      />
    </Route>
    <Route path="registro">
      <Route
        index
        element={
          <Suspense fallback={<LoadingPage />}>
            <Registro />
          </Suspense>
        }
      />
      <Route
        path="actualizarpassword"
        element={
          <Suspense fallback={<LoadingPage />}>
            <ResetPassword />
          </Suspense>
        }
      />
      <Route
        path="validar-numero"
        element={
          <Suspense fallback={<LoadingPage />}>
            <ResetPassword />
          </Suspense>
        }
      />
    </Route>
    <Route
      path="terminos"
      element={
        <Suspense fallback={<LoadingPage />}>
          <Terminos />
        </Suspense>
      }
    />
    <Route path="*" element={<Navigate to="login" />} />
  </Route>,
);
