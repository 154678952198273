import { useEffect } from 'react';
import { useWebSocket } from './useWebSocket';
import { ChangeDeliveryStatusEvent } from '@interfaces/OrderTypes';
import { DELIVERY_STATUS_MAP, SOCKET_EVENTS } from '@utils/constants';
import { useNotificationContext } from '@context/NotificationsContext';
import { useOrderActions, useOrdersFromStore } from '@store/actions/orders';
import { useNavigate } from 'react-router-dom';

export const useChangeDeliveryStatus = () => {
  const navigate = useNavigate();
  const { subscribeEvent, unsubscribeEvent } = useWebSocket();
  const orders = useOrdersFromStore();
  const { updateOrder } = useOrderActions();
  const { addNotification, removeNotification } = useNotificationContext();

  useEffect(() => {
    const onChangeDeliveryStatus = async ({ orderId, status }: ChangeDeliveryStatusEvent) => {
      const order = orders.find((item) => item.id === orderId);
      if (!order) return;

      if (status === DELIVERY_STATUS_MAP.canceled.key) {
        addNotification({
          id: order.id + DELIVERY_STATUS_MAP.canceled.key,
          title: 'Acción requerida',
          description: 'Pedido No: ' + order.folio,
          body: <p className="mr-3">Cliente: {order.nombre}</p>,
          icon: DELIVERY_STATUS_MAP.canceled.icon,
          accentColor: DELIVERY_STATUS_MAP.canceled.color,
          mute: true,
          acceptLabel: 'Revisar',
          onAccept: () => {
            removeNotification(order.id + DELIVERY_STATUS_MAP.canceled.key);
            navigate(`/control-pedidos/reparto-cancelado/${order.id}`);
          },
        });
      }

      updateOrder({ ...order, delivery_status: status, delivery_driver_info: undefined });
    };
    subscribeEvent(SOCKET_EVENTS.CHANGE_DELIVERY_STATUS, onChangeDeliveryStatus);

    return () => {
      unsubscribeEvent(SOCKET_EVENTS.CHANGE_DELIVERY_STATUS, onChangeDeliveryStatus);
    };
  }, [updateOrder]);
};
