/* eslint-disable complexity */
/* global GMAPS_API_KEY */

import { Toaster } from 'react-hot-toast';
import { RouterProvider } from 'react-router-dom';
import { useJsApiLoader } from '@react-google-maps/api';
import { useAuth } from '@context/AuthContext';
import { Libraries } from '@interfaces/Map';
import { GoogleMapsProvider } from '@context/GoogleMapsContext';
import { getRouter } from '@pages/routes/router';
import { ThemeProvider } from '@plick/ui';
import { plickTheme } from './theme';

// Following lines force creation of colors
// bg 'bg-color-primary',
// bg 'bg-color-secondary',
// bg 'bg-color-third',
// bg 'bg-color-warning',
// bg 'bg-color-whatsapp',
// bg 'bg-color-gray_light
// bg 'bg-color-red_notification
// bg 'bg-color-whatsapp_dark',
// bg 'bg-color-green',
// hover bg 'hover:bg-color-primary',
// hover bg 'hover:bg-color-secondary',
// hover bg 'hover:bg-color-secondary_light',
// hover bg 'hover:bg-color-gray',
// hover bg 'hover:bg-color-gray_light',
// hover bg 'hover:bg-color-gray_dark',
// hover bg 'hover:bg-color-red_notification',
// hover bg 'hover:bg-color-primary_light',
// hover bg 'hover:bg-color-warning',
// text 'text-color-primary',
// text 'text-color-secondary',
// text 'text-color-third',
// text 'text-color-whatsapp'
// text 'text-color-red_notification'
// border 'border-color-primary',
// border 'border-color-secondary',
// border 'border-color-third',
// border 'border-color-green',

const libraries: Libraries = ['places'];

const App = () => {
  const auth = useAuth();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GMAPS_API_KEY,
    libraries,
  });

  const router = getRouter(auth);

  return (
    <ThemeProvider theme={plickTheme}>
      <GoogleMapsProvider isLoaded={isLoaded}>
        <RouterProvider router={router} />
        <Toaster
          position="top-center"
          toastOptions={{
            duration: 3000,
            style: {
              fontSize: 'medium',
              background: '#FF9966',
              color: '#fff',
              width: 400,
              minHeight: 60,
            },
            success: {
              style: {
                backgroundColor: '#028D46',
              },
            },
            error: {
              style: {
                backgroundColor: '#d54c4c',
              },
            },
          }}
        />
      </GoogleMapsProvider>
    </ThemeProvider>
  );
};

export default App;
