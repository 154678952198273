import type { FC, ReactNode } from 'react';
import { Icon } from '@iconify/react';

type PreferenceSectionProps = {
  title: string;
  children: ReactNode;
  icon: string;
  iconSize?: string;
};

const PreferenceSection: FC<PreferenceSectionProps> = ({ title, children, icon, iconSize = '30px' }) => {
  return (
    <section className="bg-color-gray_light px-5 py-4 rounded-2xl justify-center w-full sm:w-auto">
      <section className="flex flex-col items-start h-full justify-between">
        <div className="flex items-center mb-[9px]">
          <Icon className="text-color-secondary text-4xl" icon={icon} width={iconSize} />
          <h1 className="text-xl font-bold ml-1">{title}</h1>
        </div>
        {children}
      </section>
    </section>
  );
};

export default PreferenceSection;
