import { FC, useState, lazy } from 'react';
import DefaultImgBussiness from '@assets/images/DefaultImgBussiness.png';
import BtnMenu from '@assets/images/iconos-aside-panel-menu.svg';
import BtnORders from '@assets/images/iconos-aside-panel-orders.svg';
import BtnPubli from '@assets/images/iconos-aside-panel-anuncio.svg';
import onlinePaymentIcon from '@assets/images/onlinePaymentIcon.svg';
import BtnQR from '@assets/images/iconos-aside-panel-qr.svg';
import CouponIcon from '@assets/images/iconos-aside-panel-coupons.svg';
import UsersIcon from '@assets/images/icono-mis clientes.svg';
import IconoQ from '@assets/images/icono-question.svg';
import priceIcon from '@assets/images/priceIcon.svg';
import IconoEstadisticas from '@assets/images/iconos-aside-panel-estadisticas.svg';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@context/AuthContext';
import { useGetCommerce } from '@pages/Commerce/hooks/commerce';
import useModules from '@utils/hooks/useModules';
import ButtonWithDropDown from '@components/interface/ButtonWithDropDown';
import ButtonWithIconLeft from '@components/interface/ButtonWithIconLeft';
import NavBarBackground from './components/layout/NavBarBackground';
import NavBarContainer from './components/layout/NavBarContainer';
import NavBarToggleButton from './components/interface/NavBarToggleButton';
const NavBarLogo = lazy(() => import('./components/interface/NavBarLogo'));
import NavBarButton from './components/interface/NavBarButton';
import Tooltip from '@components/interface/Tooltip';
import LazyLoad from '@components/LazyLoad';
import NavBarStatus from './components/interface/NavBarStatus';
import { ModuleName } from '@utils/constants';
import { useSubscription } from '@pages/Subscriptionsv2/hooks/useSubscriptions';
import Loading from '@components/LoadingProcess';

const getNotIncludedOnCurrentPlanIcon = (hasModule: boolean) => {
  return hasModule
    ? null
    : {
        icon: 'solar:tag-price-bold',
        color: '#FF3939',
        width: '18px',
        containerClassName: 'absolute right-0',
      };
};

const getNavigatePath = (hasModule: boolean, path: string) => {
  return hasModule ? path : 'suscripcion/' + path;
};

// eslint-disable-next-line complexity
const NavBarDesktop: FC = () => {
  const navigate = useNavigate();
  const [isExpanded, setExpanded] = useState(false);
  const { user } = useAuth();
  const { isFreemium, isTrial, planId } = useSubscription();
  const { commerce, isFetching } = useGetCommerce();
  const { hasModule } = useModules();

  return (
    <NavBarBackground isExpanded={isExpanded}>
      <NavBarToggleButton isExpanded={isExpanded} onToggle={() => setExpanded(!isExpanded)} />
      {!planId ? (
        <Loading />
      ) : (
        <NavBarContainer>
          <div className="flex-1">
            <LazyLoad>
              <NavBarLogo src={commerce.logotipo || DefaultImgBussiness} isExpanded={isExpanded} />
            </LazyLoad>
            <div className={`transition-[margin] bg-color-white ${isExpanded ? 'mt-14' : 'mt-10'}`}>
              {isExpanded && (
                <section className="px-2 justify-center flex flex-col">
                  <h5 className="text-lg text-color-white lg:text-color-primary px-2 text-center whitespace-nowrap overflow-hidden text-ellipsis">
                    {commerce.nombre}
                  </h5>
                  <NavBarStatus isOpen={commerce.servicio_activo ?? true} />
                </section>
              )}
            </div>
            {isExpanded && (
              <div className="px-2 mt-4">
                <h5 className="flex items-center gap-x-2">
                  Bienvenido: <span className="font-bold text-ellipsis overflow-hidden">{user.username}</span>
                </h5>
              </div>
            )}
            <div className="flex lg:flex-col mt-4 max-h-80 overflow-y-auto overflow-x-hidden">
              {(isFreemium || hasModule(ModuleName.HISTORIAL)) && (
                <NavBarButton
                  isExpanded={isExpanded}
                  to="control-pedidos"
                  id="control-pedidos-link"
                  icon={BtnORders}
                  label="Control de pedidos"
                  notIncludedOnCurrentPlan={!hasModule(ModuleName.HISTORIAL)}
                />
              )}

              {(isFreemium || hasModule(ModuleName.ESTADISTICAS)) && (
                <NavBarButton
                  isExpanded={isExpanded}
                  to="estadisticas"
                  id="estadisticas-link"
                  icon={IconoEstadisticas}
                  label="Estadísticas"
                  notIncludedOnCurrentPlan={!hasModule(ModuleName.ESTADISTICAS)}
                />
              )}
              {(isFreemium || hasModule(ModuleName.MENU)) && (
                <NavBarButton
                  isExpanded={isExpanded}
                  to="menu"
                  id="menu-link"
                  icon={BtnMenu}
                  label="Menú"
                  notIncludedOnCurrentPlan={!hasModule(ModuleName.MENU)}
                />
              )}
              {(isFreemium || hasModule(ModuleName.CUPONES)) && (
                <NavBarButton
                  isExpanded={isExpanded}
                  to="cupones"
                  id="cupones-link"
                  icon={CouponIcon}
                  label="Cupones"
                  notIncludedOnCurrentPlan={!hasModule(ModuleName.CUPONES)}
                />
              )}
              {(isFreemium || hasModule(ModuleName.COBROS_EN_LINEA)) && !isFetching && commerce?.business_id && (
                <NavBarButton
                  isExpanded={isExpanded}
                  to="pedidos-en-linea"
                  id="pedidos-en-linea-link"
                  icon={onlinePaymentIcon}
                  label="Cobros en línea"
                  notIncludedOnCurrentPlan={!hasModule(ModuleName.COBROS_EN_LINEA)}
                />
              )}
              {(isFreemium || hasModule(ModuleName.ANUNCIOS)) && (
                <NavBarButton
                  isExpanded={isExpanded}
                  to="anuncios"
                  id="anuncios-link"
                  icon={BtnPubli}
                  label="Crear anuncios"
                  notIncludedOnCurrentPlan={!hasModule(ModuleName.ANUNCIOS)}
                />
              )}
              {(isFreemium || hasModule(ModuleName.COMPARTIR)) && (
                <NavBarButton
                  isExpanded={isExpanded}
                  to="compartir-menu"
                  id="compartir-link"
                  icon={BtnQR}
                  label="Compartir menú"
                  notIncludedOnCurrentPlan={!hasModule(ModuleName.COMPARTIR)}
                />
              )}
              {(isFreemium || hasModule(ModuleName.ASISTENCIA_TECNICA)) && (
                <>
                  <NavBarButton
                    isExpanded={isExpanded}
                    to="asistencia"
                    id="asistencia-link"
                    icon={IconoQ}
                    label="Asistencia Técnica"
                    notIncludedOnCurrentPlan={!hasModule(ModuleName.ASISTENCIA_TECNICA)}
                  />
                </>
              )}
            </div>
          </div>
          <div className="w-full flex justify-center border-t-[1px]">
            <Tooltip label="Configuraciones" enabled={!isExpanded}>
              <ButtonWithDropDown
                label={isExpanded ? 'Configuraciones' : ''}
                openTo="right"
                textBold={false}
                textColor="color-primary"
                iconColor="color-secondary"
                hasBorder={false}
                icon="fa6-solid:gear"
                flex={isFreemium || isTrial}
              >
                {(isFreemium || hasModule(ModuleName.DATOS_COMERCIO)) && (
                  <ButtonWithIconLeft
                    fullWidth
                    allowPropagation
                    label="Datos del comercio"
                    icon="fa-solid:store"
                    iconColor="#4298b5"
                    onClick={() => {
                      navigate(getNavigatePath(hasModule(ModuleName.DATOS_COMERCIO) || false, 'datos-del-comercio'));
                    }}
                    rightIcon={getNotIncludedOnCurrentPlanIcon(hasModule(ModuleName.DATOS_COMERCIO) || false)}
                  />
                )}
                {(isFreemium || hasModule(ModuleName.HORARIOS)) && (
                  <ButtonWithIconLeft
                    fullWidth
                    allowPropagation
                    label="Horarios"
                    icon="bxs:time-five"
                    iconColor="#4298b5"
                    onClick={() => {
                      navigate(getNavigatePath(hasModule(ModuleName.HORARIOS) || false, 'horarios'));
                    }}
                    rightIcon={getNotIncludedOnCurrentPlanIcon(hasModule(ModuleName.HORARIOS) || false)}
                  />
                )}
                {(isFreemium || hasModule(ModuleName.TARIFAS)) && (
                  <ButtonWithIconLeft
                    fullWidth
                    allowPropagation
                    label="Tarifas y entregas"
                    svgIcon={priceIcon}
                    iconColor="#4298b5"
                    onClick={() => {
                      navigate(getNavigatePath(hasModule(ModuleName.TARIFAS) || false, 'tarifas'));
                    }}
                    rightIcon={getNotIncludedOnCurrentPlanIcon(hasModule(ModuleName.TARIFAS) || false)}
                  />
                )}
                {(isFreemium || hasModule(ModuleName.USUARIOS)) && (
                  <ButtonWithIconLeft
                    fullWidth
                    allowPropagation
                    label="Alta de usuarios"
                    svgIcon={UsersIcon}
                    iconColor="#4298b5"
                    onClick={() => {
                      navigate(getNavigatePath(hasModule(ModuleName.USUARIOS) || false, 'usuarios'));
                    }}
                    rightIcon={getNotIncludedOnCurrentPlanIcon(hasModule(ModuleName.USUARIOS) || false)}
                  />
                )}
                {(isFreemium || hasModule(ModuleName.AJUSTES)) && (
                  <ButtonWithIconLeft
                    fullWidth
                    allowPropagation
                    label="Ajustes"
                    icon="fa6-solid:gear"
                    iconColor="#4298b5"
                    onClick={() => {
                      navigate(getNavigatePath(hasModule(ModuleName.AJUSTES) || false, 'ajustes'));
                    }}
                    rightIcon={getNotIncludedOnCurrentPlanIcon(hasModule(ModuleName.AJUSTES) || false)}
                  />
                )}
                <ButtonWithIconLeft
                  fullWidth
                  allowPropagation
                  label="Integraciones"
                  icon="mdi:plug"
                  iconColor="#4298b5"
                  onClick={() => {
                    navigate('/integraciones');
                  }}
                />
              </ButtonWithDropDown>
            </Tooltip>
          </div>
        </NavBarContainer>
      )}
    </NavBarBackground>
  );
};

export default NavBarDesktop;
