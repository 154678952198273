import { Suspense, FC, ReactNode } from 'react';

type LazyLoadProps = {
  children: ReactNode;
};

const LazyComponent: FC<LazyLoadProps> = ({ children }) => {
  return <Suspense fallback={<div>Cargando</div>}>{children}</Suspense>;
};

export default LazyComponent;
