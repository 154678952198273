import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Section } from '@interfaces/general';

const initialState: Section[] = [];

type SectionId = Pick<Section, 'id'>;

export const sectionsSlice = createSlice({
  name: 'sections',
  initialState,
  reducers: {
    initSections: (_, action: PayloadAction<Section[]>) => {
      return [...action.payload];
    },
    addSection: (state, action: PayloadAction<Section>) => {
      return [...state, action.payload];
    },
    updateSection: (state, action: PayloadAction<Section>) => {
      return state.map((section) => {
        if (section.id === action.payload.id) {
          return action.payload;
        }
        return section;
      });
    },
    deleteSection: (state, action: PayloadAction<SectionId>) => {
      return state.filter((section) => section.id !== action.payload.id);
    },
    reset: () => initialState,
  },
});
