import { useState, useEffect } from 'react';
import { NewsItem } from '../types/news';
import { NEWS } from '@utils/constants';
import { fetchNews } from '../services/newsService';

export const useNewsFeed = () => {
  const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
  const [showFeed, setShowFeed] = useState(false);
  const [hasNewNotification, setHasNewNotification] = useState(false);
  const [lastViewedNewsId, setLastViewedNewsId] = useState<string | null>(() =>
    localStorage.getItem('lastViewedNewsId'),
  );

  useEffect(() => {
    const updateNews = async () => {
      const sortedItems = await fetchNews();
      setNewsItems(sortedItems);

      const mostRecentNewsId = sortedItems[0]?.id;
      setHasNewNotification(mostRecentNewsId ? mostRecentNewsId !== lastViewedNewsId : false);
    };

    updateNews();
    const interval = setInterval(updateNews, NEWS.REFRESH_INTERVAL);

    return () => clearInterval(interval);
  }, [lastViewedNewsId]);

  const handleNotificationClick = () => {
    setShowFeed(!showFeed);

    if (!showFeed) {
      const mostRecentNewsId = newsItems[0]?.id;
      if (mostRecentNewsId) {
        setLastViewedNewsId(mostRecentNewsId);
        localStorage.setItem('lastViewedNewsId', mostRecentNewsId);
        setHasNewNotification(false);
      }
    }
  };

  const handleArticleClick = (link: string) => {
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  const handleCloseFeed = () => {
    setShowFeed(false);
  };

  return {
    newsItems,
    showFeed,
    hasNewNotification,
    handleNotificationClick,
    handleArticleClick,
    handleCloseFeed,
  };
};
