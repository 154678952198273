import { electronPrintersSlice } from '@store/slices/electronPrinters';
import { useAppDispatch, useAppSelector } from '..';
import { ElectronPrinter } from '@interfaces/general';

export const useElectronPrintersFromStore = () => {
  return useAppSelector((state) => state.electronPrinters);
};

export const useElectronPrintersActions = () => {
  const dispatch = useAppDispatch();

  const initElectronPrinters = (printers: ElectronPrinter[]) =>
    dispatch(electronPrintersSlice.actions.initElectronPrinters(printers));

  return { initElectronPrinters };
};
