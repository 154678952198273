/* global REACT_APP_PUBLIC_CHAT_BASE_URL */
/* global REACT_APP_PUBLIC_CHAT_TOKEN */
/* global REACT_APP_PUBLIC_CHAT_ENABLED */

import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useAuth } from '@context/AuthContext';
interface ChatwootCustomAttributes {
  id_comercio: string | null;
  id_user: string | null;
  id_path: string | null;
  user_name: string | null;
}

interface ChatwootPayload {
  name: string | null;
  email: string | null;
  emailAddress: string | null;
  fullName: string | null;
  comercio: string | null;
  company_name: string | null;
}
declare global {
  interface Window {
    $chatwoot?: {
      setUser: (id: string, payload: ChatwootPayload) => void;
      toggle: (state?: string) => void;
      showBubble: (state: string) => void;
      isBubbleVisible: boolean;
      toggleBubbleVisibility: () => void;
      setCustomAttributes: (attributes: ChatwootCustomAttributes) => void;
      reset: () => void;
    };
    chatwootSettings: {
      hideMessageBubble: boolean;
      position: string;
      locale: string;
      type: string;
    };
    chatwootSDK: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      run: (config: any) => void;
    };
  }
}

interface ChatwootContextType {
  toggleBubbleVisibility: () => void;
  showBubble: () => void;
  reset: () => void;
  chatwootLoaded: boolean;
}

const ChatwootContext = createContext<ChatwootContextType | undefined>(undefined);

export const useChatwoot = (): ChatwootContextType => {
  const context = useContext(ChatwootContext);
  if (!context) {
    throw new Error('useChatwoot must be used within a ChatwootProvider');
  }
  return context;
};

export const ChatwootProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [chatwootLoaded, setChatwootLoaded] = useState(false);
  const auth = useAuth();

  const id_commerce = localStorage.getItem('id_commerce');
  const id_user = localStorage.getItem('id_user');
  const id_path = localStorage.getItem('id_path');
  const commerce_name = (localStorage.getItem('commerce_name') || '').toString();
  const user_name = (localStorage.getItem('user_name') || '').toString();
  const userData = localStorage.getItem('userData');

  const showBubble = () => {
    if (window.$chatwoot) {
      window.$chatwoot.toggle('open');
    }
  };

  const toggleBubbleVisibility = () => {
    if (window.$chatwoot) {
      window.$chatwoot.toggle();
    }
  };

  const reset = () => {
    if (window.$chatwoot) {
      window.$chatwoot.reset();
    }
  };

  useEffect(() => {
    if (REACT_APP_PUBLIC_CHAT_ENABLED) {
      window.chatwootSettings = {
        hideMessageBubble: true,
        position: 'right',
        locale: 'es',
        type: 'standard',
      };

      (function (d, t) {
        const BASE_URL = `${REACT_APP_PUBLIC_CHAT_BASE_URL}`;
        const scriptId = 'chatwoot-sdk-script';
        const g = d.createElement(t) as HTMLScriptElement;
        const s = d.getElementsByTagName(t)[0];

        if (s && s.parentNode && !document.getElementById(scriptId)) {
          g.id = scriptId;
          g.src = BASE_URL + '/packs/js/sdk.js';
          g.defer = true;
          g.async = true;
          s.parentNode.insertBefore(g, s);

          g.onload = function () {
            window.chatwootSDK.run({
              websiteToken: `${REACT_APP_PUBLIC_CHAT_TOKEN}`,
              baseUrl: BASE_URL,
            });
          };
        } else {
          d.body.appendChild(g);
        }
      })(document, 'script');

      window.addEventListener('chatwoot:ready', function () {
        setChatwootLoaded(true);
      });
    }
  }, []);

  useEffect(() => {
    if (REACT_APP_PUBLIC_CHAT_ENABLED && chatwootLoaded) {
      if (auth.isReady && auth.isAuthenticated) {
        const user = userData ? JSON.parse(userData) : {};
        const payload = {
          name: user?.name,
          email: user?.email,
          emailAddress: user?.email,
          fullName: user?.name,
          comercio: commerce_name,
          company_name: commerce_name,
        };
        if (window.$chatwoot) {
          window.$chatwoot.setUser(id_path || '', payload);
          window.$chatwoot.setCustomAttributes({
            id_comercio: id_commerce,
            id_user,
            id_path,
            user_name,
          });
        }
      } else if (auth.isReady && !auth.isAuthenticated && window.$chatwoot) {
        window.$chatwoot.reset();
      }
    }
  }, [chatwootLoaded, auth.isAuthenticated]);

  return (
    <ChatwootContext.Provider value={{ toggleBubbleVisibility, chatwootLoaded, showBubble, reset }}>
      {children}
    </ChatwootContext.Provider>
  );
};
