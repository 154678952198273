import { ordersWithDetailsSlice } from '../slices/ordersWithDetails';
import type { OrderDetail } from '@interfaces/OrderTypes';
import { useAppDispatch, useAppSelector } from '..';

export const useOrdersWithDetailsFromStore = () => {
  return useAppSelector((state) => state.ordersWithDetails);
};

export const useOrderWithDetailsActions = () => {
  const dispatch = useAppDispatch();

  const addOrderWithDetails = (order: OrderDetail) =>
    dispatch(ordersWithDetailsSlice.actions.addOrderWithDetails(order));

  const updateOrderWithDetails = (order: OrderDetail) =>
    dispatch(ordersWithDetailsSlice.actions.updateOrderWithDetails(order));

  return { addOrderWithDetails, updateOrderWithDetails };
};
