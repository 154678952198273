import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useFetchCallback } from '@services/services';
import { MSG_DELETE_ORDER, MSG_DELETE_ORDER_ERROR, MSG_DELETE_ORDER_OK, MSG_LIST_ERROR } from '@pages/Orders/messages';
import { ORDER_STATUS_ACTIONS, ORDER_STATUS_MAP } from '@utils/constants';
import { DeliveryRiderInfo, Order, OrderDetail } from '@interfaces/OrderTypes';
import toastValidator from '@components/interface/ToastValidator';
import { getCurrentStatus, getNextStatus } from '@utils/getOrderStatus';
import { useOrderActions, useOrdersFromStore } from '@store/actions/orders';
import { useOrderWithDetailsActions, useOrdersWithDetailsFromStore } from '@store/actions/ordersWithDetails';
import { InvalidStatusTransitionError } from '@utils/errors';

export const useDetailedOrders = () => {
  const orders = useOrdersWithDetailsFromStore();
  const { addOrderWithDetails } = useOrderWithDetailsActions();
  const { isFetching, call: fetchOrderDetail } = useFetchCallback<OrderDetail>('get', '/v1/pedido');

  const getOrder = async (id: string, token?: string) => {
    const existingOrder = orders.find((item) => item.pedido.id === id);
    if (existingOrder) {
      return existingOrder;
    }

    try {
      const order = await fetchOrderDetail({ path: id }, token);
      const mappedOrder: OrderDetail = {
        ...order,
        pedido: {
          ...order.pedido,
          metodo_pago: order.pedido.metodo_pago.toLowerCase() as OrderDetail['pedido']['metodo_pago'],
        },
      };
      if (mappedOrder && mappedOrder.pedido.estatus !== 'pending') {
        addOrderWithDetails(mappedOrder);
      }
      return mappedOrder;
    } catch (e) {
      toast.error('No se encontró el pedido');
      throw new Error('No se encontró el pedido');
    }
  };

  return { isFetching, getOrder, orders };
};

export const useGetOrdersWithStatus = () => {
  const orders = useOrdersFromStore();
  const { initOrders, addOrder, updateOrder } = useOrderActions();
  const { updateOrderWithDetails } = useOrderWithDetailsActions();
  const ordersWithDetails = useOrdersWithDetailsFromStore();

  const { isFetching, call: fetchOrders } = useFetchCallback<
    { orders_in_process: boolean },
    { count: number; rows: Order[] }
  >('get', '/v1/pedido?orders_in_process=true');

  const { call: handleUpdate } = useFetchCallback<{
    action: string;
    status: keyof typeof ORDER_STATUS_MAP;
  }>('put', '/v1/pedido');

  useEffect(() => {
    if (!orders.length) {
      fetchOrders({ data: { orders_in_process: true } })
        .then((response) => {
          if (response && response.rows) initOrders([...response.rows]);
        })
        .catch(() => {
          toast.error(MSG_LIST_ERROR);
        });
    }
  }, []);

  const setStatusToOrder = async (order: Order, newStatus: keyof typeof ORDER_STATUS_MAP) => {
    updateOrder({ ...order, estatus: newStatus });

    const detailedOrder = ordersWithDetails.find((item) => item.pedido.id === order.id);
    if (detailedOrder) {
      updateOrderWithDetails({
        productos: detailedOrder.productos,
        pedido: { ...detailedOrder.pedido, estatus: newStatus },
      });
    }
  };

  const handleUpdateStatus = async (order: Order, statusAction: ORDER_STATUS_ACTIONS) => {
    const newStatus = (): keyof typeof ORDER_STATUS_MAP => {
      if (statusAction === 'reject') return ORDER_STATUS_MAP.rejected.key;
      if (statusAction === 'next') return getNextStatus(order).key;

      return getCurrentStatus(order).key;
    };

    const prevStatus = order.estatus || ORDER_STATUS_MAP.pending.key;
    try {
      const newOrderStatus = newStatus();
      setStatusToOrder(order, newOrderStatus);
      await handleUpdate({
        path: order.id,
        data: {
          action: 'changeStatus',
          status: newOrderStatus,
        },
      });
    } catch (err) {
      if (err instanceof InvalidStatusTransitionError) {
        setStatusToOrder(order, err.data.currentStatus);
        return;
      }

      setStatusToOrder(order, prevStatus);
      toast.error('Error al actualizar el estatus del pedido');
    }
  };

  const setAssignedRider = (order: Order, rider: DeliveryRiderInfo) => {
    updateOrder({ ...order, delivery_driver_info: rider });

    const detailedOrder = ordersWithDetails.find((item) => item.pedido.id === order.id);
    if (detailedOrder) {
      updateOrderWithDetails({
        productos: detailedOrder.productos,
        pedido: { ...detailedOrder.pedido, delivery_driver_info: rider },
      });
    }
  };

  return {
    isFetching,
    orders,
    setStatusToOrder,
    handleAddOrder: addOrder,
    handleUpdateStatus,
    handleUpdateOrder: updateOrder,
    setAssignedRider,
  };
};

export const useOrderDelete = () => {
  const { deleteOrder } = useOrderActions();
  const { call: handleDelete, isFetching } = useFetchCallback('delete', '/v1/pedido');

  const handleOrderDelete = (orderId: string) => {
    return new Promise((result) => {
      toastValidator({
        message: MSG_DELETE_ORDER,
        onValidate: async () => {
          try {
            await handleDelete({ path: orderId });
            deleteOrder({ id: orderId });
            toast.success(MSG_DELETE_ORDER_OK);
            result(true);
          } catch (err) {
            toast.error(MSG_DELETE_ORDER_ERROR);
            result(false);
          }
        },
      });
    });
  };

  return { isDeleting: isFetching, handleOrderDelete };
};

export const useDeliveryRider = () => {
  const { call: handleRequestAdvance, isFetching: isFetchingAdvance } = useFetchCallback(
    'post',
    '/v1/delivery/request',
  );
  const { call: handleRequestNew, isFetching: isFetchingNew } = useFetchCallback<{ id_pedido: string }>(
    'post',
    '/v1/delivery/order',
  );
  const { call: handleCancel, isFetching: isCancelling } = useFetchCallback('post', '/v1/delivery/request-cancel');
  const { updateOrderWithDetails } = useOrderWithDetailsActions();

  const requestNewDeliveryRider = async (order: OrderDetail) => {
    try {
      await handleRequestNew({ data: { id_pedido: order.pedido.id } });

      updateOrderWithDetails({
        productos: order.productos,
        pedido: { ...order.pedido, has_requested_rider: true, delivery_status: 'pending' },
      });
      toast.success('Se ha enviado la solicitud al repartidor');
    } catch (err) {
      toast.error('Error al enviar la solicitud');
    }
  };

  const requestAnticipateDeliveryRider = async (order: OrderDetail) => {
    try {
      await handleRequestAdvance({ path: order.pedido.id });

      updateOrderWithDetails({ productos: order.productos, pedido: { ...order.pedido, has_requested_rider: true } });
      toast.success('Se ha enviado la solicitud al repartidor');
    } catch (err) {
      toast.error('Error al enviar la solicitud');
    }
  };

  const cancelDeliveryRider = async (order: OrderDetail) => {
    try {
      await handleCancel({ path: order.pedido.id });

      updateOrderWithDetails({
        productos: order.productos,
        pedido: { ...order.pedido, has_requested_rider: false, delivery_status: 'canceled' },
      });
      toast.success('Se ha cancelado la solicitud al repartidor');
    } catch (err) {
      toast.error('Error al cancelar el repartidor');
    }
  };

  return {
    requestAnticipateDeliveryRider,
    cancelDeliveryRider,
    requestNewDeliveryRider,
    isRequestingNewRider: isFetchingNew,
    isAnticipatingRider: isFetchingAdvance,
    isCancellingRider: isCancelling,
  };
};
