import { IconExternalLink } from '@tabler/icons-react';
import { memo } from 'react';
import { NewsItem as NewsItemType } from '../../../../types/news';
import { CategoryList } from '../components/CategoryList';

type NewsItemProps = {
  item: NewsItemType;
  onClick: (link: string) => void;
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('es-MX', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const NewsItem = memo(({ item, onClick }: NewsItemProps) => (
  <div
    className="flex flex-col mb-2 pb-4 cursor-pointer p-2 rounded shadow-md gap-2 border-b-2 border-color-gray_stack_title hover:bg-color-primary_dark group transition-colors duration-200 "
    onClick={() => onClick(item.link)}
  >
    <div className="flex gap-2 justify-between items-center text-color-primary_dark group-hover:text-color-white">
      <h3 className="font-semibold text-sm flex-1 line-clamp-2">{item.title}</h3>
      <IconExternalLink className="flex-shrink-0 w-5 h-5" />
    </div>
    <div className="flex flex-col gap-2">
      <p className="text-xs text-color-gray_ultra_dark line-clamp-3 group-hover:text-color-white">{item.description}</p>
      <div className="text-xs text-color-gray_ultra_dark line-clamp-2 group-hover:text-color-white">
        {formatDate(item.date)} - <CategoryList categories={item.category} />
      </div>
    </div>
  </div>
));

NewsItem.displayName = 'NewsItem';
