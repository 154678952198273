import { useEffect } from 'react';
import { useWebSocket } from '@utils/hooks/useWebSocket';
import { useDetailedOrders, useGetOrdersWithStatus } from '../../pages/Orders/hooks/orders';
import { OrderEvent } from '@interfaces/OrderTypes';
import toast from 'react-hot-toast';
import { useNotificationContext } from '@context/NotificationsContext';
import { DELIVERY_MAP, NOTIFICATION_NAMES_MAP, PRINT_AUTO_OPTIONS_MAP, SOCKET_EVENTS } from '@utils/constants';
import { formatToCurrency } from '@utils/formats';
import { useElectronPrintSettingsFromStore } from '@store/actions/electronPrintSettings';
import { useElectron } from './useElectron';
import useModules from './useModules';
import { useSubscription } from '@pages/Subscriptionsv2/hooks/useSubscriptions';
import { useNavigate } from 'react-router-dom';
import useTracking, { TRACK_EVENT_NAMES } from '@utils/hooks/useTracking';
import { useAuth } from '@context/AuthContext';

export const useIncomingOrder = () => {
  const { subscribeEvent, unsubscribeEvent } = useWebSocket();
  const { getOrder } = useDetailedOrders();
  const { addNotification, removeNotification } = useNotificationContext();
  const { handleAddOrder, handleUpdateStatus } = useGetOrdersWithStatus();
  const allPrintersSettings = useElectronPrintSettingsFromStore();
  const { hasModule } = useModules();
  const { planId, accountCommerceTokens } = useSubscription();
  const { printOrder } = useElectron();
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const { refreshLogin } = useAuth();

  useEffect(() => {
    const onNewOrder = async (incomingOrder: OrderEvent) => {
      try {
        const commerceToken = accountCommerceTokens?.find((item) => item.id_comercio === incomingOrder.commerceId);
        const order = await getOrder(incomingOrder.orderId, commerceToken?.token);
        const isCurrentCommerce =
          !incomingOrder.commerceId || localStorage.getItem('id_commerce') === incomingOrder.commerceId || undefined;
        if (isCurrentCommerce) handleAddOrder(order.pedido);
        if (order.pedido.origin === 'telefono') {
          toast.success(`Pedido ${order.pedido.folio} registrado`);
          trackEvent(TRACK_EVENT_NAMES.MenuOrderPhoneNewOrder);

          navigate('/control-pedidos');
          if (hasModule('IMPRESION_AUTOMATICA')) {
            Object.keys(allPrintersSettings).forEach((key) => {
              if (
                allPrintersSettings[key as keyof typeof allPrintersSettings].electronPrinterName &&
                allPrintersSettings[key as keyof typeof allPrintersSettings].electronAutoPrint ===
                  PRINT_AUTO_OPTIONS_MAP.onOrderAccepted.key
              ) {
                new Array(allPrintersSettings[key as keyof typeof allPrintersSettings].electronPrintNumber)
                  .fill('mock')
                  .forEach((_, index) => {
                    setTimeout(
                      () => {
                        printOrder({
                          detailedOrder: order,
                          orderId: order.pedido.id,
                          settings: allPrintersSettings[key as keyof typeof allPrintersSettings],
                        });
                      },
                      allPrintersSettings[key as keyof typeof allPrintersSettings]
                        .electronMultiPrintDelayBetweenPrints *
                        index *
                        1000,
                    );
                  });
              }
            });
          }
        } else {
          addNotification({
            id: order.pedido.id + NOTIFICATION_NAMES_MAP.INCOMING_ORDER.key,
            title: 'Pedido nuevo',
            subtitle: `No: ${order.pedido.folio}`,
            description: `Cliente: ${order.pedido.nombre}`,
            body: (
              <div className="flex">
                <p className="font-semibold mr-3">
                  {order.productos.length} producto{order.productos.length !== 1 ? 's' : ''}
                </p>
                <p className="font-semibold text-color-secondary">Total: {formatToCurrency(order.pedido.total)}</p>
              </div>
            ),
            icon: DELIVERY_MAP[order.pedido.metodo_entrega].icon,
            accentColor: DELIVERY_MAP[order.pedido.metodo_entrega].iconColor,
            acceptLabel: isCurrentCommerce ? undefined : 'Ver',
            onAccept: isCurrentCommerce
              ? () => {
                  handleUpdateStatus(order.pedido, 'next');
                  removeNotification(order.pedido.id + NOTIFICATION_NAMES_MAP.INCOMING_ORDER.key, true);

                  if (hasModule('IMPRESION_AUTOMATICA')) {
                    Object.keys(allPrintersSettings).forEach((key) => {
                      if (
                        allPrintersSettings[key as keyof typeof allPrintersSettings].electronPrinterName &&
                        allPrintersSettings[key as keyof typeof allPrintersSettings].electronAutoPrint ===
                          PRINT_AUTO_OPTIONS_MAP.onOrderAccepted.key
                      ) {
                        new Array(allPrintersSettings[key as keyof typeof allPrintersSettings].electronPrintNumber)
                          .fill('mock')
                          .forEach((_, index) => {
                            setTimeout(
                              () => {
                                printOrder({
                                  detailedOrder: order,
                                  orderId: order.pedido.id,
                                  settings: allPrintersSettings[key as keyof typeof allPrintersSettings],
                                });
                              },
                              allPrintersSettings[key as keyof typeof allPrintersSettings]
                                .electronMultiPrintDelayBetweenPrints *
                                index *
                                1000,
                            );
                          });
                      }
                    });
                  }
                }
              : () => refreshLogin(commerceToken?.token || '', commerceToken?.id_comercio || ''),
            rejectLabel: 'Ignorar',
            onReject:
              isCurrentCommerce &&
              (() => removeNotification(order.pedido.id + NOTIFICATION_NAMES_MAP.INCOMING_ORDER.key, true)),
          });
        }
        if (hasModule('IMPRESION_AUTOMATICA')) {
          Object.keys(allPrintersSettings).forEach((key) => {
            if (
              allPrintersSettings[key as keyof typeof allPrintersSettings].electronPrinterName &&
              allPrintersSettings[key as keyof typeof allPrintersSettings].electronAutoPrint ===
                PRINT_AUTO_OPTIONS_MAP.onOrderReceived.key
            ) {
              new Array(allPrintersSettings[key as keyof typeof allPrintersSettings].electronPrintNumber)
                .fill('mock')
                .forEach((_, index) => {
                  setTimeout(
                    () => {
                      printOrder({
                        detailedOrder: order,
                        orderId: order.pedido.id,
                        settings: allPrintersSettings[key as keyof typeof allPrintersSettings],
                      });
                    },
                    allPrintersSettings[key as keyof typeof allPrintersSettings].electronMultiPrintDelayBetweenPrints *
                      index *
                      1000,
                  );
                });
            }
          });
        }
      } catch (e) {
        toast.error('Error al obtener un pedido entrante');
      }
    };

    subscribeEvent(SOCKET_EVENTS.NEW_ORDER, onNewOrder);

    return () => {
      unsubscribeEvent(SOCKET_EVENTS.NEW_ORDER, onNewOrder);
    };
  }, [handleAddOrder, allPrintersSettings, planId]);
};
