import type { FC } from 'react';
import { useDesktop } from '@utils/hooks/useDesktop';
import NavBarMobile from './NavBarMobile';
import NavBarDesktop from './NavBarDesktop';

const NavBar: FC = () => {
  const isDesktop = useDesktop();

  return isDesktop ? <NavBarDesktop /> : <NavBarMobile />;
};

export default NavBar;
