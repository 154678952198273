import type { FC } from 'react';

type NavBarStatusProps = {
  isOpen: boolean;
};

const NavBarStatus: FC<NavBarStatusProps> = ({ isOpen }) => {
  return (
    <div className="flex justify-center items-center text-sm whitespace-pre">
      <div
        className={
          'w-2 h-2 rounded mr-2 shadow-md ' +
          (isOpen ? 'shadow-color-green bg-color-green' : 'shadow-color-red_notification bg-color-red_notification')
        }
      ></div>
      {isOpen ? 'Abierto' : 'Cerrado temporalmente'}
    </div>
  );
};

export default NavBarStatus;
