import { ElectronPrinter } from '@interfaces/general';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: ElectronPrinter[] = [];

export const electronPrintersSlice = createSlice({
  name: 'electronPrinters',
  initialState,
  reducers: {
    initElectronPrinters: (_, action: PayloadAction<ElectronPrinter[]>) => {
      return action.payload;
    },
    reset: () => initialState,
  },
});
