import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Coupon } from '@interfaces/CouponType';

const initialState: Coupon[] = [];

type CouponId = Pick<Coupon, 'id'>;

export const couponsSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {
    initCoupons: (_, action: PayloadAction<Coupon[]>) => {
      return [...action.payload];
    },
    addCoupon: (state, action: PayloadAction<Coupon>) => {
      return [...state, action.payload];
    },
    updateCoupon: (state, action: PayloadAction<Coupon>) => {
      return state.map((coupon) => {
        if (coupon.id === action.payload.id) {
          return action.payload;
        }
        return coupon;
      });
    },
    deleteCoupon: (state, action: PayloadAction<CouponId>) => {
      return state.filter((coupon) => coupon.id !== action.payload.id);
    },
    reset: () => initialState,
  },
});

export default couponsSlice.reducer;
