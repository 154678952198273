import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Category } from '@interfaces/general';

const initialState: Category[] = [];

type CategoryId = Pick<Category, 'id'>;

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    initCategories: (_, action: PayloadAction<Category[]>) => {
      return [...action.payload];
    },
    addCategory: (state, action: PayloadAction<Category>) => {
      return [...state, action.payload];
    },
    updateCategory: (state, action: PayloadAction<Category>) => {
      return state.map((category) => {
        if (category.id === action.payload.id) {
          return action.payload;
        }
        return category;
      });
    },
    deleteCategory: (state, action: PayloadAction<CategoryId>) => {
      return state.filter((category) => category.id !== action.payload.id);
    },
    reset: () => initialState,
  },
});
