import { useState } from 'react';
import * as Sentry from '@sentry/react';
import { PosPrintData, PosPrintOptions } from '@plick/electron-pos-printer';
import { OrderDetail } from '@interfaces/OrderTypes';
import { useDetailedOrders } from '@pages/Orders/hooks/orders';
import toast from 'react-hot-toast';
import { useGetCommerce } from '@pages/Commerce/hooks/commerce';
import { buildElectronOrderTicket } from '@pages/Orders/utils/electronTicket/buildTicket';
import { buildPrintOptions } from '@utils/buildElectronPrintOptions';
import { ElectronPrintSettings } from '@interfaces/general';

type Electron = {
  ipcRenderer: {
    invoke: <T>(channel: string, data?: unknown) => Promise<T>;
  };
};

type WindowWithElectron = {
  electron?: Electron;
} & Window;

type PrintOrderType = {
  detailedOrder?: OrderDetail;
  orderId: string;
  settings: ElectronPrintSettings;
};

export const useElectron = () => {
  const electron: Electron = (window as WindowWithElectron)?.electron || {
    ipcRenderer: {
      invoke: () => {
        throw new Error('Electron is not available, use the Electron app to use this features instead');
      },
    },
  };
  const isElectronInstance = Boolean((window as WindowWithElectron)?.electron?.ipcRenderer);
  const { ipcRenderer } = electron;
  const [isPrinting, setIsPrinting] = useState(false);
  const [isBuildingOrderTicket, setIsBuildingOrderTicket] = useState(false);
  const { getOrder } = useDetailedOrders();
  const { commerce } = useGetCommerce();

  /**
   * Use this function to send data to the electron app to print it
   * @param {PosPrintData[]} data
   * @param {PosPrintOptions} options PLICK NOTE: Use the buildPrintOptions function inside a try catch to build the options, it will throw an error if the printer name is not set.
   */
  const print = async (data: PosPrintData[], options: PosPrintOptions) => {
    try {
      setIsPrinting(true);
      await ipcRenderer.invoke('print', { data, options });
    } catch (error) {
      Sentry.captureMessage('Error ipcRenderer');
      Sentry.captureException(error);
    } finally {
      setIsPrinting(false);
    }
  };

  const printOrder = async ({ detailedOrder, orderId, settings }: PrintOrderType) => {
    if (!isElectronInstance) {
      return;
    }

    const {
      electronPrinterName,
      electronPrintTextSize,
      electronPrintLogo,
      electronPrintClientNumber,
      electronPrintCommerceData,
      electronRightMargin,
      electronLeftMargin,
    } = settings;

    try {
      setIsBuildingOrderTicket(true);
      const options = buildPrintOptions({
        printerName: electronPrinterName,
        marginRightInPX: electronRightMargin,
        marginLeftInPX: electronLeftMargin,
      });
      const order = detailedOrder || (await getOrder(orderId));
      const { ticket } = buildElectronOrderTicket({
        order,
        referencesLabel: commerce?.config_form_entrega?.referencias?.label,
        commerceAddress: commerce.direccion || '',
        commerceLogo: commerce?.logotipo && /^https?:\/\//.test(commerce?.logotipo || '') ? commerce.logotipo : '',
        commerceName: commerce.nombre,
        textSize: electronPrintTextSize,
        showClientPhone: electronPrintClientNumber,
        showCommerceAddress: electronPrintCommerceData,
        showLogo: electronPrintLogo && Boolean(commerce?.logotipo) && /^https?:\/\//.test(commerce?.logotipo || ''),
      });

      print(ticket, options);
    } catch (error) {
      if (error?.toString().includes('Printer name is required')) {
        toast.error('No se pudo imprimir, selecciona una impresora en los ajustes');
        return;
      }

      toast.error('Error al obtener los detalles del pedido o el pedido no existe');
    } finally {
      setIsBuildingOrderTicket(false);
    }
  };

  return {
    isElectronInstance,
    ipcRenderer,
    printOrder,
    print,
    isAutoPrinting: isBuildingOrderTicket || isPrinting,
  };
};
