import { AvailableSubscription } from '@interfaces/general';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: AvailableSubscription[] = [];

export const availableSubscriptionsSlice = createSlice({
  name: 'availableSubscriptions',
  initialState,
  reducers: {
    initAvailableSubscriptions: (_, action: PayloadAction<AvailableSubscription[]>) => {
      return action.payload;
    },
    reset: () => initialState,
  },
});
