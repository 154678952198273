import { Order } from '@interfaces/OrderTypes';
import { formatToCurrency } from '@utils/formats';
import { PosPrintTableField } from '@plick/electron-pos-printer';

export const getOrderFooter = (pedido: Order): PosPrintTableField[][] => {
  return [
    [
      { type: 'text', value: 'Subtotal', style: { textAlign: 'right', padding: '2px', paddingTop: '5px' } },
      { type: 'text', value: formatToCurrency(pedido.subtotal || 0), style: { padding: '2px', paddingTop: '5px' } },
    ],
    pedido?.coupon_amount
      ? [
          { type: 'text', value: 'Descuento', style: { textAlign: 'right', padding: '2px' } },
          { type: 'text', value: `-${formatToCurrency(pedido.coupon_amount)}`, style: { padding: '2px' } },
        ]
      : [],
    pedido?.propina
      ? [
          { type: 'text', value: 'Propina', style: { textAlign: 'right', padding: '2px' } },
          { type: 'text', value: formatToCurrency(pedido.propina), style: { padding: '2px' } },
        ]
      : [],
    [
      { type: 'text', value: 'Costo de envío', style: { textAlign: 'right', padding: '2px' } },
      { type: 'text', value: formatToCurrency(pedido?.costo_envio || 0), style: { padding: '2px' } },
    ],
    [
      {
        type: 'text',
        value: 'Total a pagar',
        style: { textAlign: 'right', fontWeight: '600', padding: '2px' },
      },
      {
        type: 'text',
        value: formatToCurrency(pedido.total || 0),
        style: { fontWeight: '600', padding: '2px' },
      },
    ],
  ];
};
