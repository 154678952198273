import type { FC, ReactNode } from 'react';

type PreferenceItemProps = {
  children: ReactNode;
};

const PreferenceItem: FC<PreferenceItemProps> = ({ children }) => {
  return <ul className="flex flex-col mb-1 w-full last-of-type:mb-0">{children}</ul>;
};

export default PreferenceItem;
