import type { FC, ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import ButtonAction from '@components/interface/ButtonAction';

type ButtonWithDropDownProps = {
  label?: string;
  autoClose?: boolean;
  icon?: string;
  hasBorder?: boolean;
  textBold?: boolean;
  textColor?: string;
  iconColor?: string;
  openTo?: 'bottom' | 'right' | 'top';
  position?: 'relative' | 'static';
  children?: ReactNode;
  flex?: boolean;
};

const MARGIN = 20;

const ButtonWithDropDown: FC<ButtonWithDropDownProps> = ({
  label,
  autoClose,
  icon = 'dashicons:arrow-down-alt2',
  textColor = 'color-secondary',
  iconColor,
  textBold = true,
  hasBorder = true,
  openTo = 'bottom',
  position = 'relative',
  children,
  flex,
}) => {
  const [open, setOpen] = useState(false);
  const drop = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open) {
      requestAnimationFrame(() => {
        if (contentRef.current) {
          const rect = contentRef.current.getBoundingClientRect();
          let x = 0,
            y = 0;
          if (openTo === 'right') {
            if (contentRef.current.parentElement)
              x =
                contentRef.current.parentElement.offsetWidth +
                contentRef.current.parentElement.offsetLeft +
                -rect.x +
                MARGIN;
          }

          if (openTo === 'top') {
            y = -rect.height - MARGIN;
            x = -50;
          } else if (openTo === 'bottom') {
            if (rect.y + rect.height > window.innerHeight - MARGIN) {
              y = -(rect.y + rect.height) + window.innerHeight - 2 * MARGIN;
            }
          }

          if (rect.y + rect.height > window.innerHeight - MARGIN) {
            y = -(rect.y + rect.height) + window.innerHeight - 2 * MARGIN;
          }
          contentRef.current.style.transform = `translate(${x}px,${y}px)`;
          if (flex) drop.current?.classList.add('flex');
        }
      });
      const handleClick = (e: MouseEvent) => {
        if (drop.current && !e.composedPath().includes(drop.current)) setOpen(false);
      };
      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
        if (flex) drop.current?.classList.remove('flex');
      };
    }
  }, [open]);

  return (
    <div
      ref={drop}
      className={`${position} w-full lg:w-auto select-none`}
      onClick={(ev) => {
        document.body.click();
        ev.stopPropagation();
      }}
    >
      <ButtonAction
        textButton={label}
        hoverBg="color-gray_light"
        icon={icon}
        colorBg="transparent"
        textBold={textBold}
        colorIcon={iconColor}
        colorTxt={textColor}
        hasBorder={hasBorder}
        onClick={() => setOpen(!open)}
      />
      {open && (
        <div
          ref={contentRef}
          className="absolute -translate-y-5 bg-color-white drop-shadow-xl z-10 rounded-lg p-2.5 right-5 lg:right-0 transition-transform ease-out"
          onClick={(ev) => {
            if (autoClose) setOpen(false);
            else ev.stopPropagation();
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default ButtonWithDropDown;
