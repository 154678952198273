import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { User } from '@pages/Users/types';

const initialState: User[] = [];

type UserId = Pick<User, 'id'>;

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    initUsers: (_, action: PayloadAction<User[]>) => {
      return [...action.payload];
    },
    addUser: (state, action: PayloadAction<User>) => {
      return [...state, action.payload];
    },
    updateUser: (state, action: PayloadAction<User>) => {
      return state.map((user) => {
        if (user.id === action.payload.id) {
          return action.payload;
        }
        return user;
      });
    },
    deleteUser: (state, action: PayloadAction<UserId>) => {
      return state.filter((user) => user.id !== action.payload.id);
    },
    reset: () => initialState,
  },
});
