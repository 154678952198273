import toast from 'react-hot-toast';
import ButtonAction from '@components/interface/ButtonAction';

type ToastValidatorProps = { message: string; onValidate: () => void; onCancel?: () => void };
const toastValidator = ({ message, onValidate, onCancel }: ToastValidatorProps) => {
  toast((t) => {
    if (t.style) {
      t.style.backgroundColor = '#d54c4c';
    }
    t.duration = 10000;
    return (
      <div>
        <span>{message}</span>
        <div className="flex w-full justify-center">
          <ButtonAction
            colorBg="color-warning"
            colorTxt="color-white"
            textButton="SI"
            onClick={() => {
              toast.dismiss(t.id);
              onValidate();
            }}
          />
          <ButtonAction
            colorBg="color-warning"
            colorTxt="color-white"
            hasBorder
            textButton="NO"
            onClick={() => {
              toast.dismiss(t.id);
              if (onCancel) onCancel();
            }}
          />
        </div>
      </div>
    );
  });
};

export default toastValidator;
