import { ProductExtra, ProductOrder } from '@interfaces/OrderTypes';
import { formatToCurrency } from '@utils/formats';
import { PosPrintTableField } from '@plick/electron-pos-printer';

const getExtras = (extras: ProductExtra[]) => {
  const data: PosPrintTableField[][] = extras.map((extra) => {
    return [
      {
        type: 'text',
        value: `${extra.cantidad} x ${extra.nombre}`,
        style: { textAlign: 'left', paddingLeft: '16px' },
      },
      {
        type: 'text',
        value: formatToCurrency(extra.precio),
      },
    ];
  });

  return data;
};

export const getOrderProducts = (products: ProductOrder[]) => {
  const data: PosPrintTableField[][] = products.flatMap((product) => {
    return [
      [
        {
          type: 'text',
          value: `${product.cantidad} x ${product.nombre} - ${product.presentacion}`,
          style: { textAlign: 'left', paddingRight: '5px', paddingTop: '4px' },
        },
        {
          type: 'text',
          value: formatToCurrency(product.subtotal),
          style: { paddingTop: '4px' },
        },
      ],
      product?.instrucciones
        ? [
            {
              type: 'text',
              value: product.instrucciones,
              style: { textAlign: 'left', fontWeight: '700' },
            },
          ]
        : [],
      ...getExtras(product.extras),
      [
        {
          type: 'divider',
          style: {
            paddingTop: '4px',
          },
        },
      ],
    ];
  });

  return data;
};
