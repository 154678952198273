import { createContext, useContext, useState, ReactNode, useRef, useEffect } from 'react';
import { PlickNotification } from '@interfaces/general';
import { usePreferencesFromStore } from '@store/actions/preferences';

interface NotificationContextProps {
  notifications: PlickNotification[];
  addNotification: (notification: PlickNotification) => void;
  removeNotification: (id: string, stopNotificationsSound?: boolean) => void;
}

const NotificationContext = createContext<NotificationContextProps>({
  notifications: [],
  addNotification: () => null,
  removeNotification: () => null,
});

export const useNotificationContext = () => {
  return useContext(NotificationContext);
};

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const [notifications, setNotifications] = useState<PlickNotification[]>([]);
  const { audio: storedAudio } = usePreferencesFromStore();
  const sound = useRef(new Audio(storedAudio));

  const playSound = () => {
    const audio = sound.current;
    audio.loop = true;
    audio.play();
  };

  const stopSound = () => {
    const audio = sound.current;
    audio.loop = false;
    audio.pause();
    audio.currentTime = 0;
  };

  useEffect(() => {
    return () => {
      if (sound.current) stopSound();
    };
  }, []);

  const removeNotification = (id: string, stopNotificationsSound = false) => {
    setNotifications((prev) => prev.filter((item) => item.id !== id));
    if (stopNotificationsSound) stopSound();
  };

  const addNotification = (notification: PlickNotification) => {
    setNotifications((prev) => [...prev, notification]);
    if (!notification?.mute) playSound();

    if (notification?.autoCloseTimer) {
      setTimeout(() => {
        removeNotification(notification.id, !notification?.mute);
      }, notification.autoCloseTimer - 250);
    }
  };

  const notificationValues: NotificationContextProps = {
    notifications,
    addNotification,
    removeNotification,
  };

  return <NotificationContext.Provider value={notificationValues}>{children}</NotificationContext.Provider>;
};
