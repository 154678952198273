/* global REACT_APP_PUBLIC_ENABLE_TRACKING */
/* global REACT_APP_PUBLIC_POSTHOG_KEY */
/* global REACT_APP_PUBLIC_POSTHOG_HOST */
/* global REACT_APP_PUBLIC_SENTRY_DNS */
/* global REACT_APP_PUBLIC_SENTRY_ENVIRONMENT */

import { ReactNode, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { AuthComponent } from './context/AuthContext';
import { ChatwootProvider } from '@context/ChatwootContext';
import * as Sentry from '@sentry/react';
import { PostHogProvider } from 'posthog-js/react';
import { Provider } from 'react-redux';
import { appStore } from './store';
import { ThemeProvider, baseTheme } from '@plick/ui';

import './index.css';

const rootElement = document.getElementById('app');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(rootElement!);

const isDev = REACT_APP_PUBLIC_SENTRY_ENVIRONMENT !== 'production';
const isProd = REACT_APP_PUBLIC_SENTRY_ENVIRONMENT === 'production';
const tracesSampleRate = isDev ? 1.0 : 0.7;
if (isProd) {
  Sentry.init({
    dsn: REACT_APP_PUBLIC_SENTRY_DNS,
    debug: false,
    environment: REACT_APP_PUBLIC_SENTRY_ENVIRONMENT,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracePropagationTargets: ['127.0.0.1', 'localhost', /^https:\/\/amazonaws\.com\//],
    // Performance Monitoring
    tracesSampleRate: tracesSampleRate,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const PostHogHOC = ({ children }: { children: ReactNode }) => {
  return !REACT_APP_PUBLIC_ENABLE_TRACKING ? (
    children
  ) : (
    <PostHogProvider
      apiKey={REACT_APP_PUBLIC_POSTHOG_KEY}
      options={{
        api_host: REACT_APP_PUBLIC_POSTHOG_HOST,
        opt_in_site_apps: true,
      }}
    >
      {children}
    </PostHogProvider>
  );
};

root.render(
  <StrictMode>
    <ThemeProvider theme={baseTheme}>
      <Provider store={appStore}>
        <AuthComponent>
          <PostHogHOC>
            <ChatwootProvider>
              <App />
            </ChatwootProvider>
          </PostHogHOC>
        </AuthComponent>
      </Provider>
    </ThemeProvider>
  </StrictMode>,
);
