import { ElectronPrintSettings } from '@interfaces/general';
import { Printers } from '@pages/Preferences/components/MultiPrinters';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PRINT_AUTO_OPTIONS_MAP, PRINT_TEXT_SIZE_MAP } from '@utils/constants';

const initialState = (): Record<keyof typeof Printers, ElectronPrintSettings> => {
  const keys = Object.keys(Printers) as (keyof typeof Printers)[];

  return keys.reduce(
    (acc, key) => {
      const fixedKey = key === Printers.Printer1.key ? '' : key;

      acc[key] = {
        electronPrinterName: localStorage.getItem(`${fixedKey}electronPrinterName`) || null,
        electronPrintLogo: !fixedKey
          ? localStorage.getItem(`${fixedKey}electronPrintLogo`) !== 'false'
          : localStorage.getItem(`${fixedKey}electronPrintLogo`) === 'true',
        electronPrintCommerceData: !fixedKey
          ? localStorage.getItem(`${fixedKey}electronPrintCommerceData`) !== 'false'
          : localStorage.getItem(`${fixedKey}electronPrintCommerceData`) === 'true',
        electronPrintClientNumber: !fixedKey
          ? localStorage.getItem(`${fixedKey}electronPrintClientNumber`) !== 'false'
          : localStorage.getItem(`${fixedKey}electronPrintClientNumber`) === 'true',
        electronPrintTextSize:
          (localStorage.getItem(`${fixedKey}electronPrintTextSize`) as keyof typeof PRINT_TEXT_SIZE_MAP) ||
          PRINT_TEXT_SIZE_MAP.small.key,
        electronRightMargin: Number(localStorage.getItem(`${fixedKey}electronRightMargin`)) || 0,
        electronAutoPrint:
          (localStorage.getItem(`${fixedKey}electronAutoPrint`) as keyof typeof PRINT_AUTO_OPTIONS_MAP) ||
          PRINT_AUTO_OPTIONS_MAP.onOrderAccepted.key,
        electronLeftMargin: Number(localStorage.getItem(`${fixedKey}electronLeftMargin`)) || 0,
        electronPrintNumber: Number(localStorage.getItem(`${fixedKey}electronPrintNumber`)) || 1,
        electronMultiPrintDelayBetweenPrints:
          Number(localStorage.getItem(`${fixedKey}electronMultiPrintDelayBetweenPrints`)) || 3.5,
      };

      return acc;
    },
    {} as Record<keyof typeof Printers, ElectronPrintSettings>,
  );
};

export const electronPrintSettingsSlice = createSlice({
  name: 'electronPrintSettings',
  initialState: initialState(),
  reducers: {
    updateElectronPrintSettings: (
      state,
      action: PayloadAction<{ settings: Partial<ElectronPrintSettings>; printerKey: keyof typeof Printers }>,
    ) => {
      const { settings, printerKey } = action.payload;
      let newState = { ...state[printerKey] };

      Object.keys(settings).forEach((key) => {
        const settingKey = key as keyof ElectronPrintSettings;
        const value = settings[settingKey];

        if (value === null || value === undefined) {
          return;
        }

        newState = { ...newState, [settingKey]: value };

        localStorage.setItem(
          `${printerKey === Printers.Printer1.key ? '' : printerKey}${settingKey}`,
          value.toString(),
        );
      });

      return { ...state, [printerKey]: newState };
    },

    reset: () => initialState(),
  },
});
