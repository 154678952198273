import { useCallback } from 'react';

type Subscriber<K> = (data: K) => void;

const cache: Record<string, { subs: Subscriber<unknown>[]; data: unknown }> = {};

const getCache = <K>(key: keyof typeof cache): { subs: Subscriber<K>[]; data: K } => {
  if (!cache[key])
    cache[key] = {
      subs: [],
      data: undefined,
    };
  return cache[key] as { subs: Subscriber<K>[]; data: K };
};

export const getCacheData = <K>(key: keyof typeof cache): K | undefined => {
  return cache[key] ? (cache[key].data as K) : undefined;
};

export const createCache = <K>(key: keyof typeof cache) => {
  const cacheObj = getCache<K>(key);

  const subscribeUpdate = (sub: Subscriber<K>) => {
    cacheObj.subs.push(sub);
  };

  const unsubscribeUpdate = (sub: Subscriber<K>) => {
    cacheObj.subs.splice(cacheObj.subs.indexOf(sub), 1);
  };

  const updateCache = (data: K) => {
    cacheObj.data = data;
    cacheObj.subs.forEach((sub) => sub(data));
  };

  return { subscribeUpdate, unsubscribeUpdate, updateCache };
};

export const updateCacheItem = <K extends { id: string }>(
  cacheKey: keyof typeof cache,
  { id, data }: { id: string; data: K },
) => {
  const cacheObj = getCache<K[]>(cacheKey);
  if (Array.isArray(cacheObj.data)) {
    const idx = cacheObj.data.findIndex((item) => item.id === id);
    if (idx >= 0) {
      cacheObj.data[idx] = data;
      cacheObj.subs.forEach((sub) => sub([...cacheObj.data]));
    }
  }
};

export const deleteCacheItem = <K extends { id: string }>(cacheKey: keyof typeof cache, itemId: string) => {
  const cacheObj = getCache<K[]>(cacheKey);
  if (Array.isArray(cacheObj.data)) {
    const idx = cacheObj.data.findIndex((item) => item.id === itemId);
    if (idx >= 0) {
      cacheObj.data.splice(idx, 1);
      const newData: K[] = cacheObj.data.slice();
      cacheObj.subs.forEach((sub) => sub(newData));
    }
  }
};

const callbacks = new Map();
export const deleteRelatedCache = (relatedCacheKey: string) => {
  Object.keys(cache).forEach((key) => {
    if (key.includes(relatedCacheKey)) {
      cache[key].data = undefined;
      cache[key].subs = [];
    }
  });
  if (callbacks.has(relatedCacheKey)) {
    const set: Set<() => void> = callbacks.get(relatedCacheKey);
    for (const callback of set) {
      callback();
    }
  }
};

export const clearCache = () => {
  Object.keys(cache).forEach((key) => {
    cache[key].data = undefined;
    cache[key].subs = [];
  });
};

export const useDeleteCacheEffect = (callback: () => void, relatedKeys: string[]) => {
  const cachedCB = useCallback(callback, relatedKeys);
  relatedKeys.forEach((key) => {
    if (!callbacks.has(key)) callbacks.set(key, new Set([cachedCB]));
    else {
      const set: Set<() => void> = callbacks.get(key);
      set.add(cachedCB);
    }
  });
};
