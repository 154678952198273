import type { Commerce } from '@interfaces/general';
import { useAppDispatch, useAppSelector } from '..';
import { commerceSlice } from '@store/slices/commerce';

export const useCommerceFromStore = () => {
  return useAppSelector((state) => state.commerce);
};

export const useCommerceActions = () => {
  const dispatch = useAppDispatch();

  const updateCommerce = (commerce: Partial<Commerce>) => dispatch(commerceSlice.actions.updateCommerce(commerce));

  return { updateCommerce };
};
