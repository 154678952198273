import { useEffect, useRef } from 'react';

const mouseClickEvents = ['mousedown', 'click', 'mouseup'];

const simulateMouseClick = (element: HTMLDivElement) => {
  mouseClickEvents.forEach((mouseEventType) =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      }),
    ),
  );
};

export const useSimulateClick = () => {
  const itemToClickRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!itemToClickRef?.current) return;
    simulateMouseClick(itemToClickRef.current);
  });

  return <div className="hidden" ref={itemToClickRef}></div>;
};
