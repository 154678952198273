export const openExternalUrl = (url: string): void => {
  if (!url) return;

  try {
    window.open(url, '_blank', 'noopener,noreferrer');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error opening external URL:', error);
  }
};
