import { ProviderCode } from '@interfaces/Integration';
import { plickTheme } from '@theme/index';

export const weekDays = [
  { value: '1', label: 'Lunes' },
  { value: '2', label: 'Martes' },
  { value: '3', label: 'Miercoles' },
  { value: '4', label: 'Jueves' },
  { value: '5', label: 'Viernes' },
  { value: '6', label: 'Sábado' },
  { value: '0', label: 'Domingo' },
];

export const time_zones = [
  { value: 'America/Mexico_City', label: 'Ciudad de méxico' },
  { value: 'America/Cancun', label: 'Cancún' },
  { value: 'America/Chihuahua', label: 'Chihuahua' },
  { value: 'America/Ojinaga', label: 'Ciudad Juarez' },
];

export const ModuleName = {
  ESTADISTICAS: 'ESTADISTICAS', // Listar
  ESTADISTICAS_AVANZADAS: 'ESTADISTICAS_AVANZADAS', // Listar
  MENU: 'MENU', // Listar, Eliminar, Editar, Crear, Ordenar
  MENU_TEMAS: 'MENU_TEMAS',
  HISTORIAL: 'HISTORIAL', // Listar, Eliminar, Editar
  COBROS_EN_LINEA: 'COBROS_EN_LINEA', // Listar, Eliminar, Editar
  ANUNCIOS: 'ANUNCIOS', // Listar, Crear, Eliminar, Editar
  COMPARTIR: 'COMPARTIR', // Listar
  CUPONES: 'CUPONES', // Listar, Eliminar, Editar, Crear, Ordenar
  ASISTENCIA_TECNICA: 'ASISTENCIA_TECNICA', // Listar
  DATOS_COMERCIO: 'DATOS_COMERCIO', // Listar, Editar
  HORARIOS: 'HORARIOS', // Listar, Eliminar, Editar, Crear
  TARIFAS: 'TARIFAS', // Listar, Editar
  CLIENTES: 'CLIENTES',
  USUARIOS: 'USUARIOS', // Listar, Eliminar, Editar, Crear
  AJUSTES: 'AJUSTES', // Listar, Editar
  PROVEEDORES_ENTREGA: 'PROVEEDORES_ENTREGA', // Listar, Crear, Eliminar
  ENVIO_POR_KM: 'ENVIO_POR_KM', // Listar
  IMPRESION_AUTOMATICA: 'IMPRESION_AUTOMATICA', // Listar
  PROVEEDORES_TPV: 'PROVEEDORES_TPV', // Listar, Crear, Eliminar,
  ORDENES_TELEFONICAS: 'ORDENES_TELEFONICAS', // Listar
  PROVEEDORES_PAGO: 'PROVEEDORES_PAGO',
  PEDIDOS_MULTISUCURSAL: 'PEDIDOS_MULTISUCURSAL', // Listar
  SEGUIMIENTO_REDES_SOCIALES: 'SEGUIMIENTO_REDES_SOCIALES', // Listar
  COVER_COMMERCE: 'COVER_COMMERCE', // Listar, Editar
} as const;

export const MODULES_PATH = {
  [ModuleName.HISTORIAL]: '/control-pedidos',
  [ModuleName.ESTADISTICAS]: '/estadisticas',
  [ModuleName.MENU]: '/menu',
  [ModuleName.COBROS_EN_LINEA]: '/pedidos-en-linea',
  [ModuleName.ANUNCIOS]: '/anuncios',
  [ModuleName.COMPARTIR]: '/compartir-menu',
  [ModuleName.CUPONES]: '/cupones',
  [ModuleName.ASISTENCIA_TECNICA]: '/asistencia',
  [ModuleName.DATOS_COMERCIO]: '/datos-del-comercio',
  [ModuleName.HORARIOS]: '/horarios',
  [ModuleName.TARIFAS]: '/tarifas',
  [ModuleName.CLIENTES]: '/clientes',
  [ModuleName.USUARIOS]: '/usuarios',
  [ModuleName.AJUSTES]: '/ajustes',
  [ModuleName.PROVEEDORES_ENTREGA]: '/tarifas/delivery-partners',
  [ModuleName.ORDENES_TELEFONICAS]: '/control-pedidos/registrar-pedido',
} as const;

export const defaultLatLng = {
  // Villahermosa. Mexico
  latitud: 17.983299,
  longitud: -92.948272,
};

export const defaultLatLng2 = {
  // Villahermosa. Mexico
  latitude: 17.983299,
  longitude: -92.948272,
};

export const DELIVERY_MAP = {
  delivery: {
    key: 'delivery',
    icon: 'ic:round-delivery-dining',
    iconColor: '#00B7C2',
    color: 'color-third',
    label: 'Enviar a domicilio',
  },
  takeAway: {
    key: 'takeAway',
    icon: 'fa6-solid:basket-shopping',
    iconColor: '#4298B5',
    color: 'color-secondary',
    label: 'Pasar a recoger',
  },
  inRestaurant: {
    key: 'inRestaurant',
    icon: 'fa-solid:store',
    iconColor: '#253746',
    color: 'color-primary',
    label: 'Comer en restaurante',
  },
} as const;

export const ORDER_COUNT = 30;
export const COUPON_COUNT = 30;

export const PAYMENT_STATUS_MAP = {
  pagado: { key: 'pagado', label: 'Pagado', color: plickTheme.colors.success },
  pendiente: { key: 'pendiente', label: 'Pendiente', color: plickTheme.colors.warning },
  cancelado: { key: 'cancelado', label: 'Cancelado', color: '' },
} as const;

export const ORDER_STATUS_MAP = {
  pending: { key: 'pending', label: 'Pendiente', color: plickTheme.colors.accent, icon: 'solar:pause-bold' },
  rejected: {
    key: 'rejected',
    label: 'Rechazado',
    color: plickTheme.colors.danger,
    icon: 'material-symbols:no-food-sharp',
  },
  accepted: { key: 'accepted', label: 'Aceptado', color: plickTheme.colors.warning, icon: 'ri:fire-fill' },
  arrived_at_pickup: {
    key: 'arrived_at_pickup',
    label: 'Repartidor llegó a recoger',
    color: plickTheme.colors.help,
    icon: 'fluent:shopping-bag-add-20-filled',
  },
  on_the_way: {
    key: 'on_the_way',
    label: 'En camino',
    color: plickTheme.colors.secondary,
    icon: 'fa6-solid:motorcycle',
  },
  arrived_at_delivery: {
    key: 'arrived_at_delivery',
    label: 'Repartidor llegó',
    color: plickTheme.colors.successDark,
    icon: 'ion:home-sharp',
  },
  delivered: { key: 'delivered', label: 'Entregado', color: plickTheme.colors.primary, icon: 'fluent:food-24-filled' },
  ready_to_pick_up: {
    key: 'ready_to_pick_up',
    label: 'Listo para recoger',
    color: plickTheme.colors.primary,
    icon: 'icon-park-solid:cooking',
  },
} as const;

export const DELIVERY_STATUS_MAP = {
  completed: { key: 'completed', label: 'Completado' },
  pending: { key: 'pending', label: 'Pendiente' },
  canceled: {
    key: 'canceled',
    label: 'Reparto cancelado',
    color: plickTheme.colors.danger,
    icon: 'pepicons-pop:motorcycle-circle-off',
  },
} as const;

export const STATUS_ORDER_DELIVERY_PROVIDERS = [
  ORDER_STATUS_MAP.pending.key,
  ORDER_STATUS_MAP.accepted.key,
  ORDER_STATUS_MAP.arrived_at_pickup.key,
  ORDER_STATUS_MAP.on_the_way.key,
  ORDER_STATUS_MAP.arrived_at_delivery.key,
  ORDER_STATUS_MAP.delivered.key,
];

export const STATUS_ORDER_DELIVERY = [
  ORDER_STATUS_MAP.pending.key,
  ORDER_STATUS_MAP.accepted.key,
  ORDER_STATUS_MAP.on_the_way.key,
  ORDER_STATUS_MAP.delivered.key,
];

export const STATUS_ORDER_TAKE_AWAY = [
  ORDER_STATUS_MAP.pending.key,
  ORDER_STATUS_MAP.accepted.key,
  ORDER_STATUS_MAP.ready_to_pick_up.key,
  ORDER_STATUS_MAP.delivered.key,
];

export const STATUS_ORDER_IN_RESTAURANT = [
  ORDER_STATUS_MAP.pending.key,
  ORDER_STATUS_MAP.accepted.key,
  ORDER_STATUS_MAP.delivered.key,
];

export type ORDER_STATUS_ACTIONS = 'reject' | 'next' | 'prev';

export const ORDER_PAYMENT_MAP = {
  efectivo: { key: 'efectivo', label: 'Efectivo' },
  transferencia: { key: 'transferencia', label: 'Transferencia' },
  tarjeta: { key: 'tarjeta', label: 'Tarjeta' },
  enlace_pago: { key: 'enlace_pago', label: 'Pago en línea Santander' },
  payment_provider: { key: 'payment_provider', label: 'Pago en línea' },
} as const;

export const PAYMENT_OPTIONS = (Object.keys(ORDER_PAYMENT_MAP) as (keyof typeof ORDER_PAYMENT_MAP)[]).map((key) => ({
  value: key,
  label: ORDER_PAYMENT_MAP[key].label,
}));

export const DELIVERY_TYPE_MAP = {
  gratis: { key: 'gratis', label: 'Gratis' },
  estandar: { key: 'estandar', label: 'Estándar' },
  por_colonia: { key: 'por_colonia', label: 'Por Colonia' },
  por_kilometro: { key: 'por_kilometro', label: 'Por Kilómetro' },
  delivery_providers: { key: 'delivery_providers', label: 'Proveedor de delivery' },
  percent: { key: 'percent', label: 'Por Porcentaje' },
} as const;

export const SOCKET_EVENTS = {
  NEW_ORDER: 'newOrder',
  RIDER_ASSIGNED: 'deliveryRiderAssigned',
  CHANGE_ORDER_STATUS: 'changeOrderStatus',
  CHANGE_DELIVERY_STATUS: 'changeDeliveryStatus',
};

export const TELEMETRY_STORAGE_KEY = 'posthogSessionId';

export const EMPTY_VALUE = '';

export const DEFAULT_COUNTRY_CODE = 52;

export const PROVIDER_TYPE_MAP = {
  delivery: { key: 'delivery', label: 'Delivery' },
  tpv: { key: 'tpv', label: 'TPV' },
  rms: { key: 'rms', label: 'Software de Gestión de Restaurantes' },
  payment: { key: 'payment', label: 'Proveedores de pago' },
};

export interface IntegrationConfiguration {
  token?: string;
  sign?: string;
  branch_code?: string;
  api_key?: string;
}

export interface Integration {
  id?: string;
  provider_code: ProviderCode;
  provider_type: keyof typeof PROVIDER_TYPE_MAP;
  configuration: IntegrationConfiguration;
}

export interface IntegrationBalance {
  name?: string;
  balance: number;
  usableBalance?: number;
  currency?: string;
}

export const NOTIFICATION_NAMES_MAP = {
  INCOMING_ORDER: {
    key: 'INCOMING_ORDER',
  },
  RIDER_ARRIVED: {
    key: 'RIDER_ARRIVED',
  },
  ORDER_DELIVERED: {
    key: 'ORDER_DELIVERED',
  },
} as const;

export const COUPON_TYPES_MAP = {
  amount: { key: 'amount', label: 'Cantidad' },
  percent: { key: 'percent', label: 'Porcentaje' },
} as const;

export const COUPON_STATUS_MAP = {
  active: { key: 'active', label: 'Activos' },
  inactive: { key: 'inactive', label: 'Inactivos' },
};

export const TypesDropdownValues = (Object.keys(COUPON_TYPES_MAP) as (keyof typeof COUPON_TYPES_MAP)[]).map((key) => ({
  value: key,
  label: COUPON_TYPES_MAP[key]?.label,
}));

export const SUBSCRIPTION_TYPES_MAP = {
  FREEMIUM: {
    key: 'FREEMIUM',
  },
  FUNDADOR: {
    key: 'FUNDADOR',
  },
  BASICO: {
    key: 'BASICO',
  },
  PROFESIONAL: {
    key: 'PROFESIONAL',
  },
  PLUS: {
    key: 'PLUS',
  },
  TRIAL: {
    key: 'TRIAL',
  },
};

export const PRINT_TEXT_SIZE_MAP = {
  xxs: {
    key: 'xxs',
    label: 'Muy pequeño',
  },
  small: {
    key: 'small',
    label: 'Pequeño',
  },
  medium: {
    key: 'medium',
    label: 'Mediano',
  },
  large: {
    key: 'large',
    label: 'Grande',
  },
  xxl: {
    key: 'xxl',
    label: 'Muy grande',
  },
};

export const PRINT_TEXT_SIZES_VALUES_MAP = {
  xxs: { title: '13px', subTitle: '10px', medium: '8px', content: '7px', small: '5px' },
  small: { title: '15px', subTitle: '12px', medium: '10px', content: '9px', small: '7px' },
  medium: { title: '17px', subTitle: '14px', medium: '12px', content: '11px', small: '9px' },
  large: { title: '19px', subTitle: '16px', medium: '14px', content: '13px', small: '11px' },
  xxl: { title: '21px', subTitle: '18px', medium: '16px', content: '15px', small: '13px' },
} as const;

export const PRINT_AUTO_OPTIONS_MAP = {
  onOrderReceived: {
    key: 'onOrderReceived',
    label: 'Al recibir un pedido',
  },
  onOrderAccepted: {
    key: 'onOrderAccepted',
    label: 'Al aceptar un pedido',
  },
  disabled: {
    key: 'disabled',
    label: 'Desactivado',
  },
};

export const ERROR_CODE = {
  INVALID_STATUS_TRANSITION: 'INVALID_STATUS_TRANSITION',
};

export const COLORS = {
  secondary: '#4298B5',
  bgTable: '#EDF5F7',
  textBase: '#253746',
} as const;

export const EXTERNAL_URLS = {
  IDEAS: 'https://ideas.plick.com.mx/',
  FACEBOOK: 'https://www.facebook.com/plickmx',
  INSTAGRAM: 'https://www.instagram.com/plickmx/?hl=es',
  BLOG_UPDATES: 'https://blog.plick.com.mx/tag/actualizaciones-de-producto/',
} as const;

export const NEWS = {
  REFRESH_INTERVAL: 5 * 60 * 1000, // 5 minutos
  API_URL: 'https://n8n.jorgeliglabs.com/webhook/ed0d70f7-76ec-4929-815f-e2ddf800c030',
} as const;
