/* global URL_API_IMAGES */

import axios from 'axios';

const instance = axios.create({
  baseURL: URL_API_IMAGES,
  timeout: 30 * 1000,
  headers: {},
});

instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default instance;
