import { useRef } from 'react';
import type { FC, ReactElement } from 'react';

type TooltipProps = {
  label: string;
  children: ReactElement;
  enabled?: boolean;
};

let timeout: ReturnType<typeof setTimeout> | undefined;
const TOOLTIP_TIME = 300;

const Tooltip: FC<TooltipProps> = ({ label, enabled = true, children }) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const displayTooltip = () => {
    if (!timeout) {
      if (tooltipRef.current && tooltipRef.current.parentElement) {
        const x =
          tooltipRef.current.parentElement.offsetWidth +
          tooltipRef.current.parentElement.offsetLeft +
          tooltipRef.current.offsetLeft;
        tooltipRef.current.style.transform = `translate(${x - 5}px,0)`;

        timeout = setTimeout(() => {
          timeout = undefined;
          if (tooltipRef.current && tooltipRef.current.parentElement) {
            tooltipRef.current.classList.remove('hidden');

            requestAnimationFrame(() => {
              if (tooltipRef.current && !tooltipRef.current.classList.contains('hidden')) {
                tooltipRef.current.style.transform = `translate(${x + 5}px,0)`;
              }
            });
          }
        }, TOOLTIP_TIME);
      }
    }
  };
  const hideTooltip = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = undefined;
    } else if (tooltipRef.current) {
      tooltipRef.current.classList.add('hidden');
    }
  };

  return enabled ? (
    <div onMouseEnter={displayTooltip} onMouseLeave={hideTooltip}>
      <span
        ref={tooltipRef}
        className="absolute hidden cursor-auto text-color-primary whitespace-nowrap bg-color-white drop-shadow-md px-3 text-sm py-2 rounded-lg transition-transform"
      >
        {label}
      </span>
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

export default Tooltip;
