import { Icon } from '@iconify/react';
import { FC, HTMLInputTypeAttribute, useRef, useState } from 'react';

type InputWLabelProps = {
  value?: number | string;
  type?: HTMLInputTypeAttribute;
  textLabel?: string;
  textPlaceHolder?: string;
  autoComplete?: string;
  error?: boolean;
  preValue?: string;
  preIcon?: string;
  preValueColor?: string;
  postValue?: string;
  postValueIcon?: string;
  postValueIconColor?: string;
  min?: number | string;
  max?: number | string;
  autoFocus?: boolean;
  name?: string;
  required?: boolean;
  errorMessages?: string;
  onChange: (value: string) => void;
  onChangeEvent?: (File: File | Blob) => void;
  disabled?: boolean;
  accept?: string;
  avoidPasswordReveal?: boolean;
};

const InputWLabel: FC<InputWLabelProps> = ({
  accept,
  textLabel,
  textPlaceHolder,
  autoComplete,
  preValue,
  preIcon,
  preValueColor = 'color-primary',
  postValue,
  postValueIcon,
  postValueIconColor,
  type,
  error,
  min,
  max,
  value,
  onChange,
  onChangeEvent,
  autoFocus,
  name,
  errorMessages = 'Requerido',
  disabled = false,
  avoidPasswordReveal = false,
}) => {
  const [isRevealed, setRevealed] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className="flex flex-col mb-2.5 w-full">
      {textLabel && <span className={`font-medium ${error ? 'text-color-warning' : ''}`}>{textLabel}:</span>}
      <div className="relative flex text-gray-600 focus-within:text-gray-400 items-stretch">
        {preValue && (
          <span
            className={`flex-1 border border-solid border-${preValueColor} bg-${preValueColor} w-full py-2 text-sm text-color-white rounded-l-md p-2`}
          >
            {preValue}
          </span>
        )}
        {preIcon && (
          <span
            className={`flex-1 border border-solid border-${preValueColor} bg-${preValueColor} w-full py-2 text-sm text-color-white ${
              preValue ? '' : 'rounded-l-md'
            } p-2`}
          >
            <Icon className="text-xl" icon={preIcon} />
          </span>
        )}
        <input
          accept={accept}
          disabled={disabled}
          ref={inputRef}
          className={`border border-solid min-w-[4ch] ${
            error ? 'border-color-warning' : 'border-color-primary'
          }  outline-none appearance-none w-full py-2 text-sm text-color-primary bg-gray-900 ${
            preValue || preIcon ? 'rounded-r-md' : 'rounded-md'
          } ${
            postValue || postValueIcon ? 'rounded-r-none' : ''
          } p-2 focus:outline-none focus:bg-white focus:text-gray-900 transition-colors
          ${type === 'password' ? 'pr-12' : ''} placeholder:text-color-gray_dark`}
          placeholder={textPlaceHolder}
          value={value}
          autoFocus={autoFocus}
          type={type}
          min={min}
          max={max}
          maxLength={typeof max === 'number' ? max : undefined}
          name={name}
          autoComplete={autoComplete}
          onChange={(e) => {
            if (type === 'file' && e.target.files && onChangeEvent) {
              onChangeEvent(e.target.files[0]);
              return;
            }
            onChange(e.target.value);
          }}
        />
        {postValue && !postValueIcon && (
          <span className="flex-1 border border-solid border-color-primary bg-color-primary w-full py-2 text-sm text-color-white rounded-r-md p-2">
            {postValue}
          </span>
        )}
        {!postValue && postValueIcon && (
          <span className="flex-1 border border-solid border-color-primary bg-color-primary p-1 text-sm text-color-white rounded-r-md grid place-content-center">
            <Icon icon={postValueIcon} color={postValueIconColor} />
          </span>
        )}
        {type === 'password' && !avoidPasswordReveal ? (
          <Icon
            icon={isRevealed ? 'bi:eye-fill' : 'bi:eye-slash-fill'}
            className="absolute h-full right-1 text-2xl text-color-gray_dark cursor-pointer hover:text-color-gray_ultra_dark"
            onClick={() => {
              if (inputRef.current) {
                if (inputRef.current.type === 'password') {
                  inputRef.current.type = 'text';
                  setRevealed(true);
                } else {
                  setRevealed(false);
                  inputRef.current.type = 'password';
                }
              }
            }}
          />
        ) : null}
        {error && <span className="-bottom-4 absolute text-xs font-bold text-color-warning">{errorMessages}</span>}
      </div>
    </div>
  );
};

export default InputWLabel;
