import { IconBellFilled } from '@tabler/icons-react';

type NewsButtonProps = {
  hasNewNotification: boolean;
  onClick: () => void;
};

export const NewsButton = ({ hasNewNotification, onClick }: NewsButtonProps) => (
  <div className="relative cursor-pointer flex items-center focus:outline-none" onClick={onClick}>
    <IconBellFilled className="h-6 w-6 text-color-white" />
    {hasNewNotification && (
      <div className="bg-color-red_notification absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 w-3 h-3 rounded-full" />
    )}
  </div>
);
