import { ERROR_CODE, ORDER_STATUS_MAP } from './constants';

export class InvalidStatusTransitionError extends Error {
  data: { currentStatus: keyof typeof ORDER_STATUS_MAP };
  constructor(data: { currentStatus: keyof typeof ORDER_STATUS_MAP }, message?: string) {
    super(message);
    this.name = ERROR_CODE.INVALID_STATUS_TRANSITION;
    this.data = data;
  }
}
