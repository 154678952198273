import { FunctionComponent } from 'react';

const Loading: FunctionComponent = () => {
  return (
    <main role="progressbar" className="flex w-full h-full justify-center items-center">
      <div className="w-20 h-20 rounded-full border-4 border-b-color-gray border-color-secondary animate-spin"></div>
    </main>
  );
};

export default Loading;
