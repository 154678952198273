import { SOCKET_EVENTS } from '@utils/constants';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

type WebSocketEvent = (typeof SOCKET_EVENTS)[keyof typeof SOCKET_EVENTS];

export interface WebSocketContextType {
  emit: (message: string) => void;
  subscribeEvent: <K>(eventId: WebSocketEvent, callback: (data: K) => void) => void;
  unsubscribeEvent: <K>(eventId: WebSocketEvent, callback: (data: K) => void) => void;
}

export const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

interface WebSocketProviderProps {
  allowConnection?: boolean;
  url: string;
  commerceId: string;
  accountId?: string;
  children: React.ReactNode;
}

const subscribers = new Map<WebSocketEvent, ((data: Record<string, unknown>) => void)[]>();

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({
  url,
  children,
  commerceId,
  accountId,
  allowConnection,
}) => {
  const [webSocket, setWebSocket] = useState<WebSocket | null>(null);

  const createWebSocket = () => {
    const socket = new WebSocket(url);

    setWebSocket(socket);
  };

  const emit = useCallback(
    (message: string) => {
      if (webSocket && webSocket.readyState === WebSocket.OPEN) {
        webSocket.send(JSON.stringify(message));
      }
    },
    [webSocket],
  );

  const subscribeEvent: WebSocketContextType['subscribeEvent'] = (eventId, callback) => {
    if (subscribers.has(eventId)) {
      const subs = subscribers.get(eventId) || [];
      subs.push(callback as (data: Record<string, unknown>) => void);
    } else {
      subscribers.set(eventId, [callback as (data: Record<string, unknown>) => void]);
    }
  };

  const unsubscribeEvent: WebSocketContextType['unsubscribeEvent'] = (eventId, callback) => {
    if (subscribers.has(eventId)) {
      const subs = subscribers.get(eventId) || [];
      subs.splice(subs.indexOf(callback as (data: Record<string, unknown>) => void), 1);
    }
  };

  useEffect(() => {
    if (!webSocket) return;
    return () => {
      if (webSocket.readyState === WebSocket.OPEN) {
        webSocket.close(1000);
        subscribers.clear();
        setWebSocket(null);
      }
    };
  }, [webSocket]);

  useEffect(() => {
    if (!allowConnection) return;
    if (!commerceId || !url) return;
    let keepAliveInterval: ReturnType<typeof setInterval> | null = null;

    if (!webSocket) {
      createWebSocket();
      return;
    }

    webSocket.onopen = () => {
      webSocket?.send(
        JSON.stringify({
          action: 'subscribe',
          message: JSON.stringify([`panel_${commerceId}`, accountId && `panel_${accountId}`].filter(Boolean)),
        }),
      );

      keepAliveInterval = setInterval(
        () => {
          webSocket?.send(JSON.stringify({ action: 'checkConnection', message: 'testConnection' }));
        },
        9.5 * 60 * 1000,
      );
    };

    webSocket.onclose = (ev) => {
      if (ev.code !== 1000) {
        createWebSocket();
      }
    };

    webSocket.onerror = () => {
      toast.error('Error en el servidor de eventos');
    };

    webSocket.onmessage = (event) => {
      if (!event.data) return;

      const data = JSON.parse(event.data);
      const eventId = data?.eventId || '';
      if (subscribers.has(eventId)) {
        const subs = subscribers.get(eventId) || [];
        subs.forEach((callback) => callback(data));
      }
    };

    return () => {
      if (keepAliveInterval) {
        clearInterval(keepAliveInterval);
      }
    };
  }, [url, commerceId, accountId, webSocket, allowConnection]);

  const contextValue: WebSocketContextType = { subscribeEvent, unsubscribeEvent, emit };

  return <WebSocketContext.Provider value={contextValue}>{children}</WebSocketContext.Provider>;
};
