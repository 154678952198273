import { OrderDetail } from '@interfaces/OrderTypes';
import { DELIVERY_MAP, ORDER_PAYMENT_MAP, PRINT_TEXT_SIZE_MAP, PRINT_TEXT_SIZES_VALUES_MAP } from '@utils/constants';
import { formatToCurrency } from '@utils/formats';
import { PosPrintData } from '@plick/electron-pos-printer';
import { getOrderProducts, getOrderFooter } from './index';

type Props = {
  order: OrderDetail;
  commerceLogo: string | null | undefined;
  commerceName: string;
  commerceAddress: string;
  referencesLabel?: string;
  textSize: keyof typeof PRINT_TEXT_SIZE_MAP;
  showLogo: boolean;
  showClientPhone: boolean;
  showCommerceAddress: boolean;
};

export const buildElectronOrderTicket = ({
  order,
  commerceLogo,
  commerceName,
  commerceAddress,
  referencesLabel: references,
  textSize: selectedTextSize,
  showLogo,
  showClientPhone,
  showCommerceAddress,
}: Props) => {
  const textSize = PRINT_TEXT_SIZES_VALUES_MAP[selectedTextSize];
  const referencesLabel = references || 'Referencias';
  const ticket: PosPrintData[] = [
    {
      type: 'image',
      url: commerceLogo || 'https://placehold.co/10x10',
      position: 'center',
      width: '110px',
      height: '110px',
      style: {
        display: showLogo ? 'block' : 'none',
      },
    },
    {
      type: 'text',
      value: 'Ticket pedido',
      style: {
        textAlign: 'center',
        fontFamily: 'sans-serif',
        fontSize: textSize.title,
        fontWeight: '600',
        paddingBottom: '2px',
      },
    },
    {
      type: 'text',
      value: 'No. ' + order.pedido.folio,
      style: {
        textAlign: 'center',
        fontFamily: 'sans-serif',
        fontSize: textSize.title,
        fontWeight: '600',
        paddingBottom: '16px',
      },
    },
    {
      type: 'text',
      value: 'Cliente:',
      style: {
        textAlign: 'center',
        fontSize: textSize.content,
        fontWeight: '400',
        padding: '0 0 2px 0',
        fontFamily: 'sans-serif',
        marginTop: '10px',
      },
    },
    {
      type: 'text',
      value: order.pedido.nombre,
      style: {
        textAlign: 'center',
        fontFamily: 'sans-serif',
        fontSize: textSize.subTitle,
        fontWeight: '600',
      },
    },
    {
      type: 'text',
      value: `Celular: ${order.pedido.country_code ? '+' + order.pedido.country_code + ' ' : ''} ${
        order.pedido.numero_celular
      }`,
      style: {
        textAlign: 'center',
        fontSize: textSize.medium,
        fontFamily: 'sans-serif',
        fontWeight: '600',
        padding: '2px 0 10px 0',
        display: showClientPhone ? 'block' : 'none',
      },
    },
    {
      type: 'text',
      value: 'Mesa: ' + order.pedido?.numero_mesa,
      style: {
        textAlign: 'center',
        fontSize: textSize.content,
        fontFamily: 'sans-serif',
        fontWeight: '600',
        padding: `${showClientPhone ? '0' : '10px'} 0 10px 0`,
        display: order.pedido?.numero_mesa ? 'block' : 'none',
      },
    },
    {
      type: 'text',
      value: 'Detalle del pedido: ',
      style: {
        textAlign: 'center',
        fontSize: textSize.content,
        fontFamily: 'sans-serif',
        fontWeight: '600',
        paddingTop: '10px',
      },
    },
    {
      type: 'table',
      style: { fontSize: textSize.content, fontFamily: 'sans-serif' },
      tableHeader: [],
      tableBody: [
        [
          { type: 'text', value: 'Artículo', style: { textAlign: 'left', fontWeight: '600', padding: '5px 2px' } },
          { type: 'text', value: 'Total', style: { fontWeight: '600', padding: '5px 2px' } },
        ],
        [
          {
            type: 'divider',
            style: {
              paddingTop: '0px',
            },
          },
        ],
        ...getOrderProducts(order.productos),
        ...getOrderFooter(order.pedido),
      ],
      tableFooter: [],
      tableHeaderStyle: {},
      tableBodyStyle: {},
      tableFooterStyle: {},
      tableBodyCellStyle: {
        padding: '1px 2px',
      },
    },
    {
      type: 'text',
      value: 'Indicaciones: ' + order.pedido?.comentario,
      style: {
        fontSize: textSize.content,
        fontFamily: 'sans-serif',
        fontWeight: '600',
        paddingBottom: '3px',
        paddingTop: '7px',
        display: order.pedido?.comentario ? 'block' : 'none',
      },
    },
    {
      type: 'text',
      value: 'Método de entrega: ' + DELIVERY_MAP[order.pedido.metodo_entrega].label,
      style: {
        fontSize: textSize.content,
        fontFamily: 'sans-serif',
        fontWeight: '600',
        paddingBottom: '3px',
        paddingTop: !order.pedido?.comentario ? '7px' : '0',
      },
    },
    {
      type: 'text',
      value: 'Tipo de pago: ' + ORDER_PAYMENT_MAP[order.pedido.metodo_pago].label,
      style: {
        fontSize: textSize.content,
        fontFamily: 'sans-serif',
        fontWeight: '600',
        paddingBottom: '3px',
      },
    },
    {
      type: 'text',
      value: 'Cliente paga con: ' + formatToCurrency(parseFloat(order.pedido?.cliente_paga_con || '0')),
      style: {
        fontSize: textSize.content,
        fontFamily: 'sans-serif',
        fontWeight: '600',
        paddingBottom: '3px',
        display:
          order.pedido?.cliente_paga_con && order.pedido?.metodo_pago === ORDER_PAYMENT_MAP.efectivo.key
            ? 'block'
            : 'none',
      },
    },
    {
      type: 'text',
      value: 'Domicilio de entrega:',
      style: {
        fontSize: textSize.content,
        fontFamily: 'sans-serif',
        fontWeight: '600',
        paddingBottom: '2px',
        display: order.pedido?.metodo_entrega === 'delivery' ? 'block' : 'none',
      },
    },
    {
      type: 'text',
      value: 'Zona o Colonia: ' + order.pedido?.colonia,
      style: {
        fontSize: textSize.content,
        fontFamily: 'sans-serif',
        fontWeight: '400',
        paddingBottom: '2px',
        display: order.pedido?.colonia ? 'block' : 'none',
      },
    },
    {
      type: 'text',
      value: 'Calle o Mza: ' + order.pedido?.calle,
      style: {
        fontSize: textSize.content,
        fontFamily: 'sans-serif',
        fontWeight: '400',
        paddingBottom: '2px',
        display: order.pedido?.calle ? 'block' : 'none',
      },
    },
    {
      type: 'text',
      value: 'Numero Exterior: ' + order.pedido?.numero_exterior,
      style: {
        fontSize: textSize.content,
        fontFamily: 'sans-serif',
        fontWeight: '400',
        paddingBottom: '2px',
        display: order.pedido?.numero_exterior ? 'block' : 'none',
      },
    },
    {
      type: 'text',
      value: 'Numero interior: ' + order.pedido?.numero_interior,
      style: {
        fontSize: textSize.content,
        fontFamily: 'sans-serif',
        fontWeight: '400',
        paddingBottom: '2px',
        display: order.pedido?.numero_interior ? 'block' : 'none',
      },
    },
    {
      type: 'text',
      value: 'Dirección: ' + order.pedido?.direccion_completa,
      style: {
        fontSize: textSize.content,
        fontFamily: 'sans-serif',
        fontWeight: '400',
        paddingBottom: '2px',
        display: order.pedido?.direccion_completa ? 'block' : 'none',
      },
    },
    {
      type: 'text',
      value: `${referencesLabel}: ${order.pedido?.referencias}`,
      style: {
        fontSize: textSize.content,
        fontFamily: 'sans-serif',
        fontWeight: '400',
        paddingBottom: '2px',
        display: order.pedido?.referencias ? 'block' : 'none',
      },
    },
    {
      type: 'divider',
      style: {
        paddingTop: '5px',
        borderBottomStyle: 'dotted',
        borderColor: 'gray',
      },
    },
    {
      type: 'text',
      value: commerceName,
      style: {
        textAlign: 'center',
        fontSize: textSize.content,
        fontFamily: 'sans-serif',
        fontWeight: '600',
        paddingBottom: '2px',
        paddingTop: '5px',
        display: showCommerceAddress ? 'block' : 'none',
      },
    },
    {
      type: 'text',
      value: commerceAddress,
      style: {
        textAlign: 'center',
        fontSize: textSize.small,
        fontFamily: 'sans-serif',
        fontWeight: '400',
        paddingBottom: '2px',
        display: showCommerceAddress ? 'block' : 'none',
      },
    },
    {
      type: 'text',
      value: 'Fecha y hora: ' + new Date(order.pedido.created_at).toLocaleString(),
      style: {
        textAlign: 'center',
        fontSize: textSize.small,
        fontFamily: 'sans-serif',
        fontWeight: '600',
        margin: `${showCommerceAddress ? '4px' : '10px'} 0 0 0`,
      },
    },
    {
      type: 'text',
      value:
        'Este formato no es un comprobante de pago, ni contrato de venta, es para uso exclusivo del vendedor. Los precios que en el consignan son válidos hasta la fecha indicada.',
      style: {
        textAlign: 'center',
        fontSize: textSize.small,
        fontFamily: 'sans-serif',
        fontWeight: '400',
        paddingTop: '4px',
      },
    },
  ];

  return { ticket };
};
