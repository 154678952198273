type CategoryListProps = {
  categories: string | string[];
};

// eslint-disable-next-line no-useless-escape
const cleanRegex = /[\[\]"]/g;

export const CategoryList = ({ categories }: CategoryListProps) => {
  if (!Array.isArray(categories)) {
    return <>{categories.replace(cleanRegex, '').trim()}</>;
  }

  if (categories.length === 1) {
    return <>{categories[0].replace(cleanRegex, '').trim()}</>;
  }

  return (
    <>
      {categories.map((cat, index) => (
        <span key={cat}>{`${cat.replace(cleanRegex, '').trim()}${index < categories.length - 1 ? ', ' : ''}`}</span>
      ))}
    </>
  );
};
