import { Icon } from '@iconify/react';
import { useDesktop } from '@utils/hooks/useDesktop';

import { PlickNotification } from '@interfaces/general';
import { useNotificationContext } from '@context/NotificationsContext';
import { useEffect, useRef } from 'react';

const Notification = ({
  title,
  subtitle,
  description,
  body,
  accentColor,
  icon,
  onAccept,
  onReject,
  rejectLabel,
  acceptLabel,
  autoCloseTimer,
}: PlickNotification) => {
  const isDesktop = useDesktop();
  const timeOutBar = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (timeOutBar.current) {
        timeOutBar.current.style.width = '0%';
      }
    }, 100);
  }, []);

  return (
    <div className="rounded-lg bg-color-white px-3 py-3" style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 10px 5px' }}>
      <div className={`flex ${isDesktop ? 'flex-row' : 'flex-col'}`}>
        <div className="flex flex-1 items-center">
          <div
            className="w-[41px] min-w-[41px] h-10 rounded-[50%] flex items-center justify-center"
            style={{ backgroundColor: accentColor }}
          >
            <Icon icon={icon} color="#ffffff" width="28px" height="28px" />
          </div>
          <div className="ml-3">
            <p className="text-lg font-semibold">
              {title} {subtitle ? <b>{subtitle}</b> : null}
            </p>
            <p>{description}</p>
            {body ? body : null}
          </div>
        </div>
        {!onAccept && !onReject ? null : (
          <div
            className="flex flex-col justify-center"
            style={{
              borderLeft: isDesktop ? '1px solid #C0C0C0' : 'none',
              width: isDesktop ? '135px' : '100%',
              marginTop: isDesktop ? '0px' : '12px',
              paddingLeft: isDesktop ? '12px' : '0px',
            }}
          >
            {onAccept && (
              <button
                className="font-bold text-color-white py-2 rounded-md flex items-center justify-center mb-1"
                style={{ backgroundColor: accentColor }}
                onClick={() => onAccept()}
              >
                {acceptLabel ? acceptLabel.toUpperCase() : 'ACEPTAR'}
                <Icon className="ml-1" icon="mingcute:check-fill" color="#ffffff" />
              </button>
            )}
            {onReject && (
              <button
                className="font-bold text-color-gray_dark py-2 flex items-center justify-center"
                onClick={() => onReject()}
                style={{ color: '#FF3939', marginTop: isDesktop ? '0' : '4px' }}
              >
                {rejectLabel ? rejectLabel.toUpperCase() : 'RECHAZAR'}
                <Icon className="ml-1" icon="ep:close-bold" color="#FF3939" />
              </button>
            )}
          </div>
        )}
      </div>
      <div
        className="h-2 rounded-md"
        ref={autoCloseTimer ? timeOutBar : null}
        style={{
          marginTop: '8px',
          backgroundColor: accentColor,
          width: '100%',
          transition: autoCloseTimer ? `width ${autoCloseTimer}ms ease-in-out` : 'none',
        }}
      ></div>
    </div>
  );
};

export const NotificationCenter = () => {
  const isDesktop = useDesktop();
  const { notifications } = useNotificationContext();
  return (
    <div
      className={`right-0 bottom-0 fixed z-[99] overflow-y-auto [&::-webkit-scrollbar]:hidden ${
        isDesktop ? 'w-[508px] mr-5 mb-3' : 'w-full my-5 mb-5'
      }`}
      style={{
        maxHeight: 'calc(100vh - 20px)',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        display: notifications.length > 0 ? 'block' : 'none',
      }}
    >
      <div className="flex flex-col justify-end gap-y-4 py-3 px-3">
        {notifications.map((notification) => {
          return <Notification key={notification.id} {...notification} />;
        })}
      </div>
    </div>
  );
};
