import { ElectronPrint } from '../cards';

type MultiPrintersProps = {
  isDisabled?: boolean;
};

export const Printers = {
  Printer1: {
    key: 'Printer1',
    label: 'Impresora principal',
  },
  Printer2: {
    key: 'Printer2',
    label: 'Ticket 2',
  },
  Printer3: {
    key: 'Printer3',
    label: 'Ticket 3',
  },
};

export const MultiPrinters = ({ isDisabled = false }: MultiPrintersProps) => {
  return (
    <>
      {Object.keys(Printers).map((key) => {
        return <ElectronPrint key={key} disabled={isDisabled} printerKey={key as keyof typeof Printers} />;
      })}
    </>
  );
};
