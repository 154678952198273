import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Product } from '@interfaces/general';

const initialState: Product[] = [];

type ProductId = Pick<Product, 'id'>;

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    initProducts: (_, action: PayloadAction<Product[]>) => {
      return [...action.payload];
    },
    addProduct: (state, action: PayloadAction<Product>) => {
      return [...state, action.payload];
    },
    updateProduct: (state, action: PayloadAction<Product>) => {
      return state.map((product) => {
        if (product.id === action.payload.id) {
          return action.payload;
        }
        return product;
      });
    },
    deleteProduct: (state, action: PayloadAction<ProductId>) => {
      return state.filter((product) => product.id !== action.payload.id);
    },
    reset: () => initialState,
  },
});
