import { useGetCommerce } from '@pages/Commerce/hooks/commerce';
import { useEffect } from 'react';
import { useElectron } from './useElectron';
import { useFetchCallback } from '@services/services';
import { useAuth } from '@context/AuthContext';
import toast from 'react-hot-toast';

export const useInitElectronVersion = () => {
  const { commerce } = useGetCommerce();
  const auth = useAuth();
  const { isElectronInstance, ipcRenderer } = useElectron();
  const { call: setVersion } = useFetchCallback<{ electron_app_version: string | null }>('put', '/v1/comercio');

  const validateElectronVersion = async () => {
    try {
      const version = (await ipcRenderer.invoke('getAppVersion')) as string;
      const installedVersion = commerce?.electron_app_version;
      if (!version || version === installedVersion) {
        return;
      }

      await setVersion({ data: { electron_app_version: version } });
    } catch (error) {
      auth.logout();
      toast.error('Gracias por ser parte de la prueba, por favor instala la versión final de la aplicación');
    }
  };

  useEffect(() => {
    if (commerce && isElectronInstance && commerce.id) {
      validateElectronVersion();
    }
  }, [commerce, isElectronInstance]);
};
