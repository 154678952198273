import { subscriptionSlice } from '@store/slices/subscription';
import { useAppDispatch, useAppSelector } from '..';
import { Subscription } from '@interfaces/general';

export const useSubscriptionFromStore = () => {
  return useAppSelector((state) => state.subscription);
};

export const useSubscriptionActions = () => {
  const dispatch = useAppDispatch();

  const initSubscription = (subscription: Subscription) =>
    dispatch(subscriptionSlice.actions.initSubscription(subscription));

  return { initSubscription };
};
