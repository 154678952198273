import { useEffect } from 'react';
import { useWebSocket } from './useWebSocket';
import { ChangeOrderStatusEvent } from '@interfaces/OrderTypes';
import { DELIVERY_TYPE_MAP, NOTIFICATION_NAMES_MAP, ORDER_STATUS_MAP, SOCKET_EVENTS } from '@utils/constants';
import { useGetOrdersWithStatus } from '@pages/Orders/hooks/orders';
import { useNotificationContext } from '@context/NotificationsContext';

export const useChangeOrderStatus = () => {
  const { subscribeEvent, unsubscribeEvent } = useWebSocket();
  const { orders, setStatusToOrder } = useGetOrdersWithStatus();
  const { addNotification } = useNotificationContext();

  useEffect(() => {
    const onChangeOrderStatus = async ({ orderId, status }: ChangeOrderStatusEvent) => {
      const order = orders.find((item) => item.id === orderId);
      if (!order) return;

      if (status === ORDER_STATUS_MAP.arrived_at_pickup.key) {
        addNotification({
          id: order.id + NOTIFICATION_NAMES_MAP.RIDER_ARRIVED.key,
          title: 'Llegó el repartidor por el pedido',
          description: 'No: ' + order.folio,
          body: (
            <div className="flex">
              <p className="font-semibold mr-3">Cliente: {order.nombre}</p>
            </div>
          ),
          icon: ORDER_STATUS_MAP.arrived_at_pickup.icon,
          accentColor: ORDER_STATUS_MAP.arrived_at_pickup.color,
          mute: true,
          autoCloseTimer: 7000,
        });
      }

      if (
        status === ORDER_STATUS_MAP.delivered.key &&
        order.delivery_type === DELIVERY_TYPE_MAP.delivery_providers.key
      ) {
        addNotification({
          id: order.id + NOTIFICATION_NAMES_MAP.ORDER_DELIVERED.key,
          title: 'Pedido entregado',
          subtitle: 'No: ' + order.folio,
          description: 'Cliente: ' + order.nombre,
          body: (
            <div className="flex">
              <p className="font-semibold mr-3">
                Hora de entrega:{' '}
                {new Date().toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric', hour12: false })}
              </p>
            </div>
          ),
          icon: ORDER_STATUS_MAP.delivered.icon,
          accentColor: ORDER_STATUS_MAP.delivered.color,
          mute: true,
          autoCloseTimer: 7000,
        });
      }

      setStatusToOrder(order, status);
    };

    subscribeEvent(SOCKET_EVENTS.CHANGE_ORDER_STATUS, onChangeOrderStatus);

    return () => {
      unsubscribeEvent(SOCKET_EVENTS.CHANGE_ORDER_STATUS, onChangeOrderStatus);
    };
  }, [setStatusToOrder]);
};
