import * as Sentry from '@sentry/react';
import { NewsItem, NewsResponse } from '../types/news';
import { NEWS } from '@utils/constants';

export const fetchNews = async (): Promise<NewsItem[]> => {
  try {
    const response = await fetch(NEWS.API_URL);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data: NewsResponse = await response.json();

    const cleanedItems = data.items.map((item) => {
      let cleanCategory;
      if (typeof item.category === 'string') {
        try {
          // Intentamos parsear si es un string que contiene un array JSON
          cleanCategory = JSON.parse(item.category);
        } catch {
          // Si falla el parse, usamos el string tal cual
          cleanCategory = item.category;
        }
      } else {
        cleanCategory = item.category;
      }

      // Aseguramos que sea array y limpiamos cada elemento
      const finalCategory = Array.isArray(cleanCategory)
        ? cleanCategory.map((cat) => cat.trim())
        : [cleanCategory.trim()];

      return {
        ...item,
        category: finalCategory,
      };
    });

    return cleanedItems.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  } catch (error) {
    Sentry.captureException(error);
    Sentry.captureMessage('Error al cargar las noticias del blog');
    return [];
  }
};
