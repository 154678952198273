import type { FC } from 'react';
import { Icon } from '@iconify/react';

type ButtonWithIconLeftProps = {
  icon?: string;
  svgIcon?: string;
  label?: string;
  fullWidth?: boolean;
  onClick: () => void;
  disabled?: boolean;
  allowPropagation?: boolean;
  iconColor?: string;
  active?: boolean;
  rightIcon?: {
    icon: string;
    color: string;
    width: string;
    containerClassName: string;
  } | null;
};

const activeStyle = 'text-color-secondary';
const baseStyleButton = 'flex justify-center hover:bg-color-gray_light cursor-pointer rounded-md';

const setButtonStyle = (fullWidth?: boolean, active?: boolean) => {
  return `${baseStyleButton} ${fullWidth ? 'w-full' : ''} ${active ? activeStyle : ''}`;
};

const ButtonWithIconLeft: FC<ButtonWithIconLeftProps> = ({
  label,
  icon,
  svgIcon,
  iconColor,
  fullWidth,
  allowPropagation,
  disabled,
  active,
  onClick,
  rightIcon,
}) => {
  return (
    <div
      className={setButtonStyle(fullWidth, active)}
      onClick={(e) => {
        if (!allowPropagation) e.stopPropagation();
        onClick();
      }}
    >
      <button
        className={`relative flex items-center ${fullWidth ? 'w-full' : ''} whitespace-nowrap h-5 rounded-l m-2.5 ${
          rightIcon ? 'pr-12' : ''
        } pl-2.5}`}
        disabled={disabled}
        onClick={(e) => {
          if (!allowPropagation) e.stopPropagation();
          onClick();
        }}
      >
        {svgIcon && <img src={svgIcon} alt="icon" className="mr-1.5" />}
        {icon && <Icon className="mr-1.5" icon={icon} color={iconColor} />}
        {label}
        {rightIcon && (
          <div className={rightIcon.containerClassName}>
            <Icon icon={rightIcon.icon} color={rightIcon.color} width={rightIcon.width} />
          </div>
        )}
      </button>
    </div>
  );
};

export default ButtonWithIconLeft;
