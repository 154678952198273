import { Order } from '@interfaces/OrderTypes';
import {
  DELIVERY_MAP,
  DELIVERY_TYPE_MAP,
  ORDER_STATUS_MAP,
  STATUS_ORDER_DELIVERY,
  STATUS_ORDER_DELIVERY_PROVIDERS,
  STATUS_ORDER_IN_RESTAURANT,
  STATUS_ORDER_TAKE_AWAY,
} from './constants';

interface getStatusListProps {
  deliveryMethod: keyof typeof DELIVERY_MAP;
  isDeliveryByProviders?: boolean;
}

const getStatusList = ({ deliveryMethod, isDeliveryByProviders = false }: getStatusListProps) => {
  if (deliveryMethod === DELIVERY_MAP.delivery.key && isDeliveryByProviders) {
    return STATUS_ORDER_DELIVERY_PROVIDERS;
  } else if (deliveryMethod === DELIVERY_MAP.delivery.key) {
    return STATUS_ORDER_DELIVERY;
  } else if (deliveryMethod === DELIVERY_MAP.takeAway.key) {
    return STATUS_ORDER_TAKE_AWAY;
  } else if (deliveryMethod === DELIVERY_MAP.inRestaurant.key) {
    return STATUS_ORDER_IN_RESTAURANT;
  }

  return [];
};

export const getNextStatus = (order: Order) => {
  const statusList = getStatusList({
    deliveryMethod: order.metodo_entrega,
    isDeliveryByProviders: order.delivery_type === DELIVERY_TYPE_MAP.delivery_providers.key,
  });
  const index = statusList.findIndex((s) => s === order.estatus);

  return index === statusList.length - 1
    ? ORDER_STATUS_MAP[statusList[index]]
    : ORDER_STATUS_MAP[statusList[index + 1]];
};

export const getCurrentStatus = (order: Order) => {
  const statusList = getStatusList({
    deliveryMethod: order.metodo_entrega,
    isDeliveryByProviders: order.delivery_type === DELIVERY_TYPE_MAP.delivery_providers.key,
  });
  const index = statusList.findIndex((s) => s === order.estatus);

  return ORDER_STATUS_MAP[statusList[index]];
};
