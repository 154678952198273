import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { ISubCommerce } from '@interfaces/Payment';
import { defaultSubCommerce } from '@utils/subCommerceInitalState';

export const commerceBankInfoSlice = createSlice({
  name: 'commerceBankInfo',
  initialState: defaultSubCommerce,
  reducers: {
    updateCommerceBankInfo: (state, action: PayloadAction<Partial<ISubCommerce>>) => {
      return { ...state, ...action.payload };
    },
    reset: () => defaultSubCommerce,
  },
});
