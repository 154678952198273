import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Extra } from '@interfaces/general';

const initialState: Extra[] = [];

type ExtraId = Pick<Extra, 'id'>;

export const extrasSlice = createSlice({
  name: 'extras',
  initialState,
  reducers: {
    initExtras: (_, action: PayloadAction<Extra[]>) => {
      return [...action.payload];
    },
    addExtra: (state, action: PayloadAction<Extra>) => {
      return [...state, action.payload];
    },
    updateExtra: (state, action: PayloadAction<Extra>) => {
      return state.map((extra) => {
        if (extra.id === action.payload.id) {
          return action.payload;
        }
        return extra;
      });
    },
    deleteExtra: (state, action: PayloadAction<ExtraId>) => {
      return state.filter((extra) => extra.id !== action.payload.id);
    },
    reset: () => initialState,
  },
});
