import JelpLogoUrl from '@assets/images/partners/jelp.png';
import LoyverseLogoUrl from '@assets/images/partners/loyverse.png';
import NetPayLogoUrl from '@assets/images/partners/netpay.png';
import StripePagoLogoUrl from '@assets/images/partners/stripe.png';
import MercadoPagoLogoUrl from '@assets/images/partners/mercadoPago.png';
import SoftRestauranteLogoUrl from '@assets/images/partners/softRestaurant.png';
import ClipLogoUrl from '@assets/images/partners/clip.png';
import { IntegrationCard, IntegrationDetail, IntegrationSection } from '@interfaces/Integration';
import { IntegrationConfiguration, ModuleName } from '@utils/constants';
import { hasFeatureFlag } from '@utils/hooks/useGetFeatureFlags';

const jelpIntegration: IntegrationCard = {
  provider_code: 'jelp',
  provider_type: 'delivery',
  logo_url: JelpLogoUrl,
  available: true,
  description: 'Automatiza las operaciones de entrega de tus pedidos recibidos en Plick.',
  website: 'https://jelp.app/',
  help_link: 'https://wa.me/5216647773919',
  disclaimer:
    'Para activar este servicio debes tener activo Envío a domicilio. Ingresa los siguientes datos de tu API Key de tu cuenta [Jelp Delivery](https://jelp.app/).',
  notes: [
    '* Pagos de tarifas y comisiones de este servicio se realizan directamente con la empresa Jelp Delivery',
    '* Al activar este servicio estas de acuerdo en que una empresa ajena a Plick realice las entregas, por lo que **Plick no se hace responsable de cualquier evento o incidencia ocurrida en la entrega**.',
  ],
  configuration: {
    branch_code: { key: 'branch_code', label: 'Código de sucursal', default: '' },
    sign: { key: 'sign', label: 'Sign', default: '' },
    api_key: { key: 'api_key', label: 'Api key', default: '' },
  },
  actions: ['connect', 'disconnect', 'detail', 'configuration'],
};

const loyverseIntegration: IntegrationCard = {
  provider_code: 'loyverse',
  provider_type: 'tpv',
  logo_url: LoyverseLogoUrl,
  available: true,
  description: 'Conecta y sincroniza tu menú Plick con el punto de venta y gestión de inventario.',
  website: 'https://loyverse.com/',
  disclaimer: 'Ingresa los siguientes datos para conectar tu cuenta de Loyverse con tu menú digital Plick.',
  notes: [
    '* Al dar clic en Guardar tu menú actual se eliminará y se copiará el que tengas creado en tu cuenta de Loyverse.',
    '* El menú solo podrá ser administrado desde tu cuenta Loyverse.',
    '* Podrás sincronizarlos cada que lo desees.',
  ],
  configuration: {
    store_id: { key: 'store_id', label: 'ID de sucursal', default: '' },
    token: { key: 'token', label: 'Token', default: '' },
  },
  actions: ['connect', 'disconnect', 'sync'],
};

const softRestaurantIntegration: IntegrationCard = {
  provider_code: 'softRestaurant',
  provider_type: 'rms',
  logo_url: SoftRestauranteLogoUrl,
  available: true,
  description: 'Integra tu menú Plick con Soft Restaurant para la gestión de tu restaurante.',
  website: 'https://softrestaurant.com/',
  disclaimer: 'Ingresa los siguientes datos para conectar tu cuenta de Softrestaurant con tu menú digital Plick.',
  notes: [
    '* Al dar clic en Guardar tu menú actual se eliminará y se copiará el que tengas creado en tu cuenta de Loyverse.',
    '* El menú solo podrá ser administrado desde tu cuenta Loyverse.',
    '* Podrás sincronizarlos cada que lo desees.',
  ],
  configuration: {
    api_key: { key: 'api_key', label: 'Api key', default: '' },
  },
  actions: ['connect', 'disconnect', 'sync'],
};

const stripeIntegration: IntegrationCard = {
  provider_code: 'stripe',
  provider_type: 'payment',
  logo_url: StripePagoLogoUrl,
  available: true,
  description: 'Agiliza los pagos en tu menú Plick con Stripe: seguridad y eficiencia en cada transacción..',
  website: 'https://stripe.com/en-mx',
  disclaimer:
    'Ingresa los siguientes datos para conectar tu cuenta de Stripe con tu menú digital Plick. Los puedes conseguir [haciendo click aquí](https://dashboard.stripe.com/account/apikeys).',
  configuration: {
    public_key: { key: 'public_key', label: 'Clave publicable', default: '', fieldType: 'public' },
    secret_key: { key: 'secret_key', label: 'Clave secreta', default: '', fieldType: 'private' },
  },
  actions: ['connect', 'disconnect'],
};

const clipIntegration: IntegrationCard = {
  provider_code: 'clip',
  provider_type: 'payment',
  logo_url: ClipLogoUrl,
  available: true,
  description: 'Integra Clip a tu sistema para ofrecer una solución de pago ágil y segura.',
  website: 'https://www.clip.mx/',
  disclaimer:
    'Ingresa los siguientes datos para conectar tu cuenta de Clip con tu menú digital Plick. Los puedes conseguir [haciendo click aquí](https://dashboard.developer.clip.mx/applications).',
  configuration: {
    public_key: { key: 'public_key', label: 'API Key', default: '', fieldType: 'public' },
    secret_key: { key: 'secret_key', label: 'Clave secreta', default: '', fieldType: 'private' },
  },
  actions: ['connect', 'disconnect'],
};

const mercadoPagoIntegration: IntegrationCard = {
  provider_code: 'mercadoPago',
  provider_type: 'payment',
  logo_url: MercadoPagoLogoUrl,
  available: true,
  description: 'Conecta tu menú Plick con Mercado Pago y transforma la manera en que procesas los pagos.',
  website: 'https://www.mercadopago.com.mx/',
  configuration: {
    public_key: { key: 'public_key', label: 'Public key', default: '', fieldType: 'public' },
    secret_key: { key: 'secret_key', label: 'Access token', default: '', fieldType: 'private' },
  },
  actions: ['connect', 'disconnect'],
};

const netPayIntegration: IntegrationCard = {
  provider_code: 'netpay',
  provider_type: 'payment',
  logo_url: NetPayLogoUrl,
  available: hasFeatureFlag('netpay-register'),
  description: 'Integra tu menú Plick con NetPay para un procesamiento de pagos en línea rápido y seguro.',
  website: 'https://netpay.mx/',
  configuration: {
    api_key: { key: 'api_key', label: 'Api key', default: '' },
  },
  actions: ['disconnect', 'connect', 'register-form'],
};

export const integrationSections: IntegrationSection[] = [
  {
    title: 'Servicios de repartidores',
    integrations: [jelpIntegration],
    moduleName: ModuleName.PROVEEDORES_ENTREGA,
  },
  {
    title: 'Puntos de venta',
    integrations: [loyverseIntegration],
    moduleName: ModuleName.PROVEEDORES_TPV,
  },
  {
    title: 'Gestion de restaurante',
    integrations: [softRestaurantIntegration],
  },
  {
    title: 'Metodos de pago',
    integrations: [stripeIntegration, clipIntegration, mercadoPagoIntegration, netPayIntegration],
  },
];

export const commerceIntegrations: IntegrationCard[] = integrationSections.flatMap((section) => section.integrations);

export const defaultIntegrationConfig: IntegrationConfiguration = {
  token: '',
  sign: '',
  branch_code: '',
  api_key: '',
};

const jelpDataBalanceCode = 'usableBalance';
export const jelpIntegrationDetail: IntegrationDetail = {
  provider_code: 'jelp',
  provider_type: 'delivery',
  availableData: [],
  cardAvailableData: [
    {
      code: jelpDataBalanceCode,
      label: 'Saldo Disponible',
      icon: 'ion:wallet',
    },
  ],
  configuration: {
    fields: [],
  },
};

export const commerceIntegrationDetails: IntegrationDetail[] = [jelpIntegrationDetail];
export const integrationDetailsMap = commerceIntegrationDetails.reduce(
  (map, detail) => {
    map[detail.provider_code] = detail;
    return map;
  },
  {} as { [key: string]: IntegrationDetail },
);

export interface IntegrationError {
  errorCode: string;
  message: string;
  requestId: string;
}

export interface ProviderData {
  [key: string]: string | number | boolean | object;
}

export interface ProviderConfigurationPayload<T> {
  providerData: T;
}

export type MissingValuesChecker<T> = (registerData: T) => (keyof T)[];
