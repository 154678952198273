import { SUBSCRIPTION_TYPES_MAP } from '@utils/constants';

export const shouldShowSubscriptionModal = (planId: keyof typeof SUBSCRIPTION_TYPES_MAP) => {
  const flagKey = 'welcomeSubscriptionFlag';
  const modalSubscriptionFlag = localStorage.getItem(flagKey);

  const {
    FREEMIUM: { key: freemium },
    TRIAL: { key: trial },
  } = SUBSCRIPTION_TYPES_MAP;

  if (planId === freemium && modalSubscriptionFlag !== freemium) {
    localStorage.setItem(flagKey, freemium);
    return '/bienvenido-freemium';
  } else if (planId === trial && modalSubscriptionFlag !== trial) {
    localStorage.setItem(flagKey, trial);
    return '/bienvenido-prueba-gratis';
  }

  return false;
};
