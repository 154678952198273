/* global globalThis */
import { FC, MouseEvent, useEffect, useRef, useState, ChangeEvent } from 'react';
import { SelectDropDownOption, SelectDropDownProps } from '@components/interface/SelectDropDown/SelectDropDownTypes';
import { DownIcon as Icon, CloseIcon } from '@assets/icons';

const SelectDropDown: FC<SelectDropDownProps> = ({
  label,
  placeHolder,
  options,
  isMulti,
  values,
  disabled,
  isSearchable,
  error,
  onExpand,
  onChange,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const selectedValue = values;
  const [searchValue, setSearchValue] = useState('');
  const searchRef = useRef<HTMLInputElement>(null);
  const dropList = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showMenu) {
      const handleClick = (e: globalThis.MouseEvent) => {
        if (dropList.current && !e.composedPath().includes(dropList.current)) setShowMenu(false);
      };
      window.addEventListener('click', handleClick);
      return () => {
        window.removeEventListener('click', handleClick);
      };
    }
  }, [showMenu]);

  useEffect(() => {
    setSearchValue('');
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  useEffect(() => {
    if (disabled === true) {
      setShowMenu(false);
    }
  }, [disabled]);

  const handleInputClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (disabled) return;
    setShowMenu(!showMenu);
    if (!showMenu && onExpand) onExpand();
  };

  const removeOption = (option: SelectDropDownOption) => {
    if (disabled) return;
    if (Array.isArray(selectedValue)) return selectedValue.filter((o) => o.value !== option.value);
  };

  const onTagRemove = (e: MouseEvent, option: SelectDropDownOption) => {
    e.stopPropagation();
    if (disabled) return;
    const newValue = removeOption(option);
    if (isMulti && newValue) {
      onChange(newValue);
    }
  };

  const isSelectionVoid = () => !selectedValue || (Array.isArray(selectedValue) && selectedValue.length === 0);

  const getDisplay = () => {
    if (isSelectionVoid()) {
      return placeHolder;
    }
    if (isMulti && Array.isArray(selectedValue)) {
      return (
        <div className="flex gap-1 flex-wrap">
          {selectedValue.map((option) => (
            <div key={option.value} className="px-2 py-1 rounded-sm flex items-center bg-color-gray">
              {option.label}
              <span onClick={(e) => onTagRemove(e, option)} className="flex items-center">
                <CloseIcon />
              </span>
            </div>
          ))}
        </div>
      );
    }
    return !Array.isArray(selectedValue) && selectedValue && selectedValue.label;
  };

  const onItemClick = (option: SelectDropDownOption) => {
    if (disabled) return;
    let newValue;
    if (isMulti) {
      if (Array.isArray(selectedValue)) {
        if (selectedValue.findIndex((o) => o.value === option.value) >= 0) {
          newValue = removeOption(option);
        } else {
          newValue = [...selectedValue, option];
        }
      } else newValue = [option];
    } else {
      newValue = option;
      setShowMenu(false);
    }
    if (newValue) {
      onChange(newValue as SelectDropDownOption & SelectDropDownOption[]);
    }
  };

  const isSelected = (option: SelectDropDownOption) => {
    if (isMulti && Array.isArray(selectedValue)) {
      return selectedValue.filter((o) => o.value === option.value).length > 0;
    }

    if (!selectedValue) {
      return false;
    }

    return !Array.isArray(selectedValue) && selectedValue.value === option.value;
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter((option) => option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0);
  };

  return (
    <div className="mb-2.5 w-full">
      {label && <span className={`font-medium ${error ? 'text-color-warning' : ''}`}>{label}</span>}
      <div
        className={`relative h-fit text-sm border border-color-primary rounded-md bg-color-white ${
          error ? 'border-color-warning' : disabled ? 'border-color-gray' : 'border-color-primary cursor-pointer'
        }`}
      >
        <div
          onClick={handleInputClick}
          className={`flex p-2 justify-between select-none  ${disabled ? 'bg-color-gray_ultra_dark/20' : ''}`}
        >
          <div className={`${isSelectionVoid() ? 'text-color-gray_dark' : ''}`}>{getDisplay()}</div>
          <div>
            <Icon />
          </div>
        </div>
        {showMenu && (
          <div ref={dropList} className="absolute z-10 w-full max-h-[30vh] overflow-y-auto bg-color-white shadow-md">
            {isSearchable && (
              <div className="p-1 bg-color-white">
                <input
                  className="p-2 w-full box-border border-[1px] rounded-md"
                  onChange={onSearch}
                  value={searchValue}
                  ref={searchRef}
                />
              </div>
            )}
            {getOptions().map((option) => (
              <div
                onClick={() => {
                  if (option.disabled) return;
                  onItemClick(option);
                }}
                key={option.value}
                className={`p-2 ${isSelected(option) && 'bg-color-gray'} select-none ${
                  option.disabled ? 'cursor-default' : 'hover:bg-color-gray_light cursor-pointer'
                } `}
              >
                <span className={`${option.disabled && 'text-color-gray'}`}>{option.label}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      {error && <span className="text-sm font-bold text-color-warning">Requerido</span>}
    </div>
  );
};

export default SelectDropDown;
