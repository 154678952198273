import { availableSubscriptionsSlice } from '../slices/availableSubscriptions';
import { useAppDispatch, useAppSelector } from '..';
import { AvailableSubscription } from '@interfaces/general';

export const useAvailableSubscriptionsFromStore = () => {
  return useAppSelector((state) => state.availableSubscriptions);
};

export const useAvailableSubscriptionsActions = () => {
  const dispatch = useAppDispatch();

  const initSubscriptions = (subscriptions: AvailableSubscription[]) =>
    dispatch(availableSubscriptionsSlice.actions.initAvailableSubscriptions(subscriptions));

  return { initSubscriptions };
};
