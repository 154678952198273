import { FC, ReactNode, useState } from 'react';
import { Icon } from '@iconify/react';

type TooltipProps = {
  textTooltip: string;
  children: ReactNode;
  hidden?: boolean;
};

const ToolTipwithChildren: FC<TooltipProps> = ({ textTooltip, children, hidden, ...rest }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="relative">
      <div
        className="absolute bottom-[85%] min-w-min min-h-fit text-color white hidden p-1.5 rounded-md w-fit bg-color-gray_dark lg:max-w-full mb-1.5 ml-2.5"
        style={show ? { display: 'block' } : {}}
      >
        <p className="">{textTooltip}</p>
        <Icon className="top-full right-[85%]" icon="bxs:down-arrow" />
      </div>
      <div
        className="w-fit"
        {...rest}
        onMouseEnter={() => (hidden ? null : setShow(true))}
        onMouseLeave={() => setShow(false)}
      >
        {children}
      </div>
    </div>
  );
};

export default ToolTipwithChildren;
