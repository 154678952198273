import { lazy, Suspense } from 'react';
import LoadingPage from '@pages/LoadingPage';
import { Navigate, Route, createRoutesFromElements } from 'react-router-dom';

const OnBoarding = lazy(() => import('@pages/OnBoarding/OnBoarding'));
const OnBoardingLayout = lazy(() => import('@components/layout/OnBoardingLayout'));

export const onBoardingRoutes = createRoutesFromElements(
  <Route
    path="/"
    element={
      <Suspense fallback={<LoadingPage />}>
        <OnBoardingLayout />
      </Suspense>
    }
  >
    <Route index element={<Navigate to="onboarding" />} />
    <Route
      path="onboarding"
      element={
        <Suspense fallback={<LoadingPage />}>
          <OnBoarding />
        </Suspense>
      }
    />
    <Route path="*" element={<Navigate to="onboarding" />} />
  </Route>,
);
