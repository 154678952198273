import { useFetchCallback } from '@services/services';
import { useIntegrationsActions, useIntegrationsFromStore } from '@store/actions/integrations';
import { Integration } from '@utils/constants';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

export const useGetIntegrations = () => {
  const integrations = useIntegrationsFromStore();
  const { initIntegrations } = useIntegrationsActions();
  const { call, isFetching } = useFetchCallback<Integration[]>('get', '/v1/integrations');

  useEffect(() => {
    if (!integrations.length) {
      call()
        .then(initIntegrations)
        .catch(() => toast.error('Error al obtener las integraciones'));
    }
  }, []);

  return {
    integrations,
    isFetching,
  };
};
