import * as Sentry from '@sentry/react';
import { useElectronPrintersActions } from '@store/actions/electronPrinters';
import { useEffect } from 'react';
import { useElectron } from './useElectron';
import toast from 'react-hot-toast';
import { ElectronPrinter } from '@interfaces/general';

export const useInitElectronPrinters = () => {
  const { initElectronPrinters } = useElectronPrintersActions();
  const { isElectronInstance, ipcRenderer } = useElectron();

  const getPrinters = async () => {
    try {
      const printers: ElectronPrinter[] = await ipcRenderer.invoke('getPrinters');
      initElectronPrinters(printers);
    } catch (error) {
      Sentry.captureMessage('Error al leer las impresoras instaladas');
      Sentry.captureException(error);
      toast.error('Error al leer las impresoras instaladas');
    }
  };

  useEffect(() => {
    if (isElectronInstance) {
      getPrinters();
    }
  }, [isElectronInstance]);
};
