import { configureStore } from '@reduxjs/toolkit';
import * as slices from './slices';
import { TypedUseSelectorHook, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

type ReducerKey<T> = T extends string & `${infer Key}Slice` ? Key : never;

type AppReducer<Type extends { [Property in keyof Type]: { reducer: unknown } }> = {
  [Property in keyof Type as ReducerKey<Property>]: Type[Property]['reducer'];
};

const reducer: AppReducer<typeof slices> = {
  sections: slices.sectionsSlice.reducer,
  products: slices.productsSlice.reducer,
  categories: slices.categoriesSlice.reducer,
  coupons: slices.couponsSlice.reducer,
  ads: slices.adsSlice.reducer,
  commerce: slices.commerceSlice.reducer,
  orders: slices.ordersSlice.reducer,
  ordersWithDetails: slices.ordersWithDetailsSlice.reducer,
  extras: slices.extrasSlice.reducer,
  users: slices.usersSlice.reducer,
  commerceBankInfo: slices.commerceBankInfoSlice.reducer,
  preferences: slices.preferencesSlice.reducer,
  availableSubscriptions: slices.availableSubscriptionsSlice.reducer,
  subscription: slices.subscriptionSlice.reducer,
  integrations: slices.integrationsSlice.reducer,
  electronPrinters: slices.electronPrintersSlice.reducer,
  electronPrintSettings: slices.electronPrintSettingsSlice.reducer,
};

export const appStore = configureStore({ reducer: reducer });

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof appStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof appStore.dispatch;

// Dispatcher hook for app store
export const useAppDispatch: () => AppDispatch = useDispatch;

// hook to retrieve the app store values
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const resetStore = () => {
  (Object.keys(slices) as (keyof typeof slices)[]).forEach((key) => {
    appStore.dispatch(slices[key].actions.reset());
  });
};
