import { ordersSlice } from '../slices/orders';
import type { Order } from '@interfaces/OrderTypes';
import { useAppDispatch, useAppSelector } from '..';

export const useOrdersFromStore = () => {
  return useAppSelector((state) => state.orders);
};

export const useOrderActions = () => {
  const dispatch = useAppDispatch();

  const initOrders = (orders: Order[]) => dispatch(ordersSlice.actions.initOrders(orders));

  const addOrder = (category: Order) => dispatch(ordersSlice.actions.addOrder(category));

  const updateOrder = (category: Order) => dispatch(ordersSlice.actions.updateOrder(category));

  const deleteOrder = (categoryId: { id: string }) => dispatch(ordersSlice.actions.deleteOrder(categoryId));

  return { initOrders, addOrder, updateOrder, deleteOrder };
};
