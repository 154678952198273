import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Advertise } from '@interfaces/general';

const initialState: Advertise[] = [];

type AdvertiseId = Pick<Advertise, 'id'>;

export const adsSlice = createSlice({
  name: 'ads',
  initialState,
  reducers: {
    initAds: (_, action: PayloadAction<Advertise[]>) => {
      return [...action.payload];
    },
    addAdvertise: (state, action: PayloadAction<Advertise>) => {
      return [...state, action.payload];
    },
    updateAdvertise: (state, action: PayloadAction<Advertise>) => {
      return state.map((advertise) => {
        if (advertise.id === action.payload.id) {
          return action.payload;
        }
        return advertise;
      });
    },
    deleteAdvertise: (state, action: PayloadAction<AdvertiseId>) => {
      return state.filter((advertise) => advertise.id !== action.payload.id);
    },
    reset: () => initialState,
  },
});
