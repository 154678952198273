import { useEffect } from 'react';
import { useWebSocket } from '@utils/hooks/useWebSocket';
import { RiderAssignmentEvent } from '@interfaces/OrderTypes';
import { SOCKET_EVENTS } from '@utils/constants';
import { toast } from 'react-hot-toast';
import { useGetOrdersWithStatus } from '@pages/Orders/hooks/orders';

export const useRiderAssigned = () => {
  const { subscribeEvent, unsubscribeEvent } = useWebSocket();
  const { orders, setAssignedRider } = useGetOrdersWithStatus();

  useEffect(() => {
    const onRiderAssigned = async (incomingAssignment: RiderAssignmentEvent) => {
      const order = orders.find((item) => item.id === incomingAssignment.orderId);
      if (order) {
        const { id, name, phone, image } = incomingAssignment;
        const delivery_driver_info = {
          id,
          name,
          phone,
          image: image?.original || image?.thumbnail,
        };

        setAssignedRider(order, delivery_driver_info);
      } else {
        toast.error('Se intentó asignar un repartidor a un pedido que ya no existe');
      }
    };

    subscribeEvent(SOCKET_EVENTS.RIDER_ASSIGNED, onRiderAssigned);

    return () => {
      unsubscribeEvent(SOCKET_EVENTS.RIDER_ASSIGNED, onRiderAssigned);
    };
  }, [orders, setAssignedRider]);
};
