import { integrationsSlice } from '../slices/integrations';
import { useAppDispatch, useAppSelector } from '..';
import { Integration } from '@utils/constants';

export const useIntegrationsFromStore = () => {
  return useAppSelector((state) => state.integrations);
};

export const useIntegrationsActions = () => {
  const dispatch = useAppDispatch();

  const initIntegrations = (integrations: Integration[]) =>
    dispatch(integrationsSlice.actions.initIntegrations(integrations));

  const addIntegration = (integration: Integration) => dispatch(integrationsSlice.actions.addIntegration(integration));

  const removeIntegration = (integration: Integration) =>
    dispatch(integrationsSlice.actions.removeIntegration(integration));

  return { initIntegrations, addIntegration, removeIntegration };
};
