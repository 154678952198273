import type { FC } from 'react';
import { Icon } from '@iconify/react';

type ButtonActionProps = {
  textButton?: string;
  icon?: string;
  isIconOnLeft?: boolean;
  iconSize?: {
    width: string;
    height: string;
  };
  colorBg: string;
  colorTxt: string;
  colorIcon?: string;
  onClick: () => void;
  hasBorder?: boolean;
  hoverBg?: string;
  disable?: boolean;
  textBold?: boolean;
  fullWidth?: boolean;
};

const ButtonAction: FC<ButtonActionProps> = ({
  textButton,
  icon,
  iconSize,
  colorBg,
  colorTxt,
  colorIcon = colorTxt,
  hasBorder,
  hoverBg,
  onClick,
  disable,
  textBold = true,
  isIconOnLeft = false,
  fullWidth,
}) => {
  return (
    <button
      disabled={disable}
      className={`flex ${isIconOnLeft ? 'flex-row-reverse' : ''} w-full ${
        !fullWidth ? 'lg:w-auto' : ''
      } border-2 border-solid items-center whitespace-nowrap justify-center h-10 ${
        textBold ? 'font-bold' : ''
      } px-4 text-${colorTxt} my-2.5 rounded-md bg-${colorBg} ${hasBorder ? `border-${colorTxt}` : 'border-hidden'} ${
        hoverBg ? `hover:bg-${hoverBg}` : ''
      }`}
      onClick={onClick}
    >
      {textButton}
      {textButton && icon && <div className="w-1"></div>}
      {icon && (
        <Icon
          className={`text-center font-bold text-${colorIcon}`}
          icon={icon}
          width={iconSize?.width}
          height={iconSize?.height}
        />
      )}
    </button>
  );
};

export default ButtonAction;
