import React from 'react';
import { Icon } from '@iconify/react';
import { useGetIntegrations } from '@pages/Integrationsv3/hooks';
import { useCommerceFromStore } from '@store/actions/commerce';
import { useElectronPrintSettingsFromStore } from '@store/actions/electronPrintSettings';
import { useElectronPrintersFromStore } from '@store/actions/electronPrinters';
import { ModuleName } from '@utils/constants';
import { useElectron } from '@utils/hooks/useElectron';
import useModules from '@utils/hooks/useModules';
import { useSubscription } from '@pages/Subscriptionsv2/hooks/useSubscriptions';
import { useNavigate } from 'react-router-dom';

const ERROR_TYPES_MAP = {
  error: { key: 'error', icon: 'material-symbols:warning', color: 'bg-color-warning' },
  info: { key: 'info', icon: 'material-symbols:info', color: 'bg-color-info' },
  warning: { key: 'warning', icon: 'material-symbols:warning', color: 'bg-color-warning_yellow' },
  success: { key: 'success', icon: 'material-symbols:check', color: 'bg-color-green' },
} as const;

type IWarning = {
  message: string | React.ReactNode;
  onClick: () => void;
  type: keyof typeof ERROR_TYPES_MAP;
  emoji?: string;
  show: boolean;
  bgColor?: string;
  hideIcon?: boolean;
};

const useStatus = () => {
  const navigate = useNavigate();
  const commerce = useCommerceFromStore();
  const { hasModule } = useModules();
  const { isElectronInstance } = useElectron();
  const printers = useElectronPrintersFromStore();
  const electronPrinterName = useElectronPrintSettingsFromStore().Printer1.electronPrinterName;
  const integrations = useGetIntegrations();
  const hasPaymentIntegrations = integrations.integrations.some(
    (integration) => integration.provider_type === 'payment',
  );
  const { lastSuscriptionInvoice } = useSubscription();

  const handleSubscriptionClick = (url?: string) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  const shouldShowSubscriptionWarning = (invoice?: { status?: string; attempt_count?: number }): boolean => {
    return invoice?.status === 'uncollectible' || (invoice?.status === 'open' && (invoice.attempt_count ?? 0) > 0);
  };

  const warnings: IWarning[] = [
    {
      message:
        'No tienes un método de envío seleccionado, tus comensales no podrán solicitar envíos a domicilio. Da click aquí para configurarlo.',
      onClick: () => navigate('/tarifas'),
      type: ERROR_TYPES_MAP.error.key,
      show: Boolean(
        hasModule(ModuleName.TARIFAS) && commerce.activar_envio_domicilio && !commerce.tipo_envio_domicilio,
      ),
    },
    {
      message:
        'No tienes una impresora principal para impresión automática de comandas. Da click aquí para configurarla.',
      onClick: () => navigate('/ajustes'),
      type: ERROR_TYPES_MAP.error.key,
      show: isElectronInstance && !electronPrinterName && printers.length > 0,
    },
    {
      message: 'No se encontró la impresora principal que tenías seleccionada. Da click aquí para ver las disponibles.',
      onClick: () => navigate('/ajustes'),
      type: ERROR_TYPES_MAP.error.key,
      show:
        isElectronInstance &&
        Boolean(electronPrinterName) &&
        printers.length > 0 &&
        printers.filter((printer) => printer.name === electronPrinterName).length === 0,
    },
    {
      message: 'No se detectaron impresoras en tu computadora. No podrás imprimir comandas automáticamente.',
      onClick: () => null,
      type: ERROR_TYPES_MAP.error.key,
      show: isElectronInstance && printers.length === 0,
    },
    {
      message: 'Se desactivó el pago con proveedores externos. Da click aquí para configurar alguno.',
      onClick: () => navigate('/integraciones'),
      type: ERROR_TYPES_MAP.error.key,
      show:
        !hasPaymentIntegrations &&
        (commerce.metodos_pago_envio_domicilio.payment_provider ||
          commerce.metodos_pago_comer_restaurant.payment_provider ||
          commerce.metodos_pago_pasar_recoger.payment_provider),
    },
    {
      message: (
        <>
          Parece que hubo un problema con el pago de tu suscripción. Da <u>click aquí</u> para intentar nuevamente tu
          pago.
        </>
      ),
      onClick: () => handleSubscriptionClick(lastSuscriptionInvoice?.hosted_invoice_url),
      type: ERROR_TYPES_MAP.error.key,
      show: shouldShowSubscriptionWarning(lastSuscriptionInvoice),
    },
    {
      message: 'Hemos actualizado la dirección de tu comercio. Da click aquí para revisarla.',
      onClick: () => navigate('/datos-del-comercio/ubicacion'),
      type: ERROR_TYPES_MAP.info.key,
      show: Boolean(commerce.direccion),
    },
  ];

  return {
    warnings,
  };
};

export const StatusBottomBar = () => {
  const { warnings } = useStatus();
  return (
    <div className="hover:cursor-pointer">
      {warnings.map((warning, index) => {
        return warning.show ? (
          <div
            key={index}
            className={`flex items-center gap-x-2 ${ERROR_TYPES_MAP[warning.type].color} px-4 py-2 text-color-white text-sm md:text-base`}
            onClick={warning.onClick}
            style={{ backgroundColor: warning.bgColor || '' }}
          >
            {!warning.hideIcon && warning?.emoji ? (
              <span className="text-2xl">{warning.emoji}</span>
            ) : !warning.hideIcon ? (
              <Icon icon={ERROR_TYPES_MAP[warning.type].icon} />
            ) : null}
            <span className="font-bold w-full">{warning.message}</span>
          </div>
        ) : null;
      })}
    </div>
  );
};
