import { NewsItem } from './NewsItem';
import { NewsButton } from './NewsButton';
import { useNewsFeed } from '../../../../hooks/useNewsFeed';
import { Button } from '@plick/ui';
import { IconSquareX } from '@tabler/icons-react';
import { useEffect, useRef } from 'react';
import { plickTheme } from '@theme/index';

export const NewsFeed = () => {
  const feedRef = useRef<HTMLDivElement>(null);
  const { newsItems, showFeed, hasNewNotification, handleNotificationClick, handleArticleClick, handleCloseFeed } =
    useNewsFeed();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (feedRef.current && !feedRef.current.contains(event.target as Node)) {
        handleCloseFeed();
      }
    };

    if (showFeed) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showFeed, handleCloseFeed]);

  return (
    <div className="relative flex items-center">
      <NewsButton hasNewNotification={hasNewNotification} onClick={handleNotificationClick} />

      {showFeed && (
        <div
          ref={feedRef}
          className="absolute top-full -left-16 md:left-auto md:right-0 mt-2 w-80 border bg-color-white rounded-lg shadow-lg p-4 max-h-96 overflow-y-auto z-10"
        >
          <h2 className="text-lg font-bold mb-4 text-color-primary_dark border-b-color-primary_dark border-b-2">
            Últimas Noticias
          </h2>
          {newsItems.length > 0 ? (
            newsItems.slice(0, 5).map((item) => <NewsItem key={item.id} item={item} onClick={handleArticleClick} />)
          ) : (
            <p className="text-color-primary_dark text-sm">No hay noticias disponibles.</p>
          )}
          <div className="flex justify-center">
            <Button.Icon
              text="Cerrar"
              size="small"
              bgColor={plickTheme.colors.textLight}
              color={plickTheme.colors.dangerLight}
              icon={<IconSquareX />}
              onClick={handleCloseFeed}
            />
          </div>
        </div>
      )}
    </div>
  );
};
