import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { OrderDetail } from '@interfaces/OrderTypes';

const initialState: OrderDetail[] = [];

export const ordersWithDetailsSlice = createSlice({
  name: 'ordersWithDetails',
  initialState,
  reducers: {
    addOrderWithDetails: (state, action: PayloadAction<OrderDetail>) => {
      return [action.payload, ...state]; // new orders are added at the beginning of the list
    },
    updateOrderWithDetails: (state, action: PayloadAction<OrderDetail>) => {
      return state.map((order) => {
        if (order.pedido.id === action.payload.pedido.id) {
          return action.payload;
        }
        return order;
      });
    },
    reset: () => initialState,
  },
});
