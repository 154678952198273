import { useState, useEffect } from 'react';

const useStoredState = (key: string, defaultValue = '') => {
  const getInitialValue = (): string => {
    try {
      const storedState = localStorage.getItem(key);
      if (storedState) {
        return JSON.parse(storedState) as string;
      }
    } catch (error) {
      window.console.error(`Error parsing stored state for key '${key}':`, error);
    }
    return defaultValue;
  };

  const [state, setState] = useState<string>(getInitialValue);

  useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(state));
    } catch (error) {
      window.console.error(`Error storing state for key '${key}':`, error);
    }
  }, [key, state]);

  const setValue = (value: string | ((prevState: string | undefined) => string)) => {
    const valueToStore =
      typeof value === 'function' ? (value as (prevState: string | undefined) => string)(state) : value;

    try {
      localStorage.setItem(key, JSON.stringify(valueToStore));
      setState(valueToStore);
    } catch (error) {
      window.console.error(`Error storing state for key '${key}':`, error);
    }
  };

  return [state, setValue] as const;
};

export default useStoredState;
