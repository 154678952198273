import type { FC, ReactNode } from 'react';

type NavBarContainerProps = {
  children: ReactNode;
};

const NavBarContainer: FC<NavBarContainerProps> = ({ children }) => {
  return (
    <div className="flex flex-col w-full min-w-full lg:ml-8 lg:bg-color-white rounded-l-xl relative p-2">
      {children}
    </div>
  );
};

export default NavBarContainer;
