import { electronPrintSettingsSlice } from '@store/slices/electronPrintSettings';
import { useAppDispatch, useAppSelector } from '..';
import { ElectronPrintSettings } from '@interfaces/general';
import { Printers } from '@pages/Preferences/components/MultiPrinters';

export const useElectronPrintSettingsFromStore = () => {
  return useAppSelector((state) => state.electronPrintSettings);
};

export const useElectronPrintSettingsActions = () => {
  const dispatch = useAppDispatch();

  const updateElectronPrintSettings = (settings: Partial<ElectronPrintSettings>, printerKey: keyof typeof Printers) =>
    dispatch(electronPrintSettingsSlice.actions.updateElectronPrintSettings({ settings, printerKey }));

  return { updateElectronPrintSettings };
};
