import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Commerce } from '@interfaces/general';
import { DEFAULT_COUNTRY_CODE } from '@utils/constants';

const defaultCommerce: Commerce = {
  correo_contacto: '',
  estatus: 'activo',
  forma_pago: 'stripe',
  id: '',
  id_cliente: '',
  id_path: '',
  id_suscripcion: '',
  imagen_qr: '',
  metodos_pago_comer_restaurant: {
    efectivo: false,
    tarjeta: false,
    transferencia: false,
    enlace_pago: false,
    payment_provider: false,
  },
  metodos_pago_envio_domicilio: {
    efectivo: false,
    tarjeta: false,
    transferencia: false,
    enlace_pago: false,
    payment_provider: false,
  },
  metodos_pago_pasar_recoger: {
    efectivo: false,
    tarjeta: false,
    transferencia: false,
    enlace_pago: false,
    payment_provider: false,
  },
  nombre: '',
  zona_horaria: '',
  nombre_contacto: '',
  tipo_plan: 'profesional',
  config_form_entrega: {
    calle: { required: true, show: true },
    colonia: { required: true, show: true },
    no_ext: { required: true, show: true },
    no_int: { required: false, show: true },
    ubicacion: { required: false, show: true },
    referencias: { required: true, show: true, label: '' },
  },
  country_code: DEFAULT_COUNTRY_CODE,
  onboarding_finish_date: '',
  address: {
    country: 'Mexico',
    state: '',
    city: '',
    neighborhood: '',
    postalCode: '',
    street: '',
    interiorNumber: '',
    exteriorNumber: '',
    fullAddress: '',
    latitude: 0,
    longitude: 0,
  },
};

export const commerceSlice = createSlice({
  name: 'commerce',
  initialState: defaultCommerce,
  reducers: {
    updateCommerce: (state, action: PayloadAction<Partial<Commerce>>) => {
      return { ...state, ...action.payload };
    },
    reset: () => defaultCommerce,
  },
});
