import React, { createContext, useContext } from 'react';

interface GoogleMapsContextProps {
  isLoaded: boolean;
  children: React.ReactNode;
}

const GoogleMapsContext = createContext<boolean>(false);

export const useGoogleMapsContext = (): boolean => useContext<boolean>(GoogleMapsContext);

export const GoogleMapsProvider: React.FC<GoogleMapsContextProps> = ({ isLoaded, children }) => (
  <GoogleMapsContext.Provider value={isLoaded}>{children}</GoogleMapsContext.Provider>
);
