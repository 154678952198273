/* global WEBSOCKET_URL */
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from './NavBar/NavBar';
import Header from './header/Header';
import { WebSocketProvider } from '@context/WebSocketContext';
import { NotificationCenter } from '@components/interface/Notification';
import { useGetCommerce } from '@pages/Commerce/hooks/commerce';
import { NotificationProvider } from '@context/NotificationsContext';
import { useIncomingOrder } from '@utils/hooks/useIncomingOrder';
import { useRiderAssigned } from '@utils/hooks/useRiderAssigned';
import { useChangeOrderStatus } from '@utils/hooks/useChangeOrderStatus';
import { useDesktop } from '@utils/hooks/useDesktop';
import { SubscriptionStatusBar } from '@pages/Subscriptionsv2/components/SubscriptionStatusBar';
import { ModuleName } from '@utils/constants';
import useModules from '@utils/hooks/useModules';
import { useInitSubscriptionDetails, useSubscription } from '@pages/Subscriptionsv2/hooks/useSubscriptions';
import { useSimulateClick } from '@utils/hooks/useSimulateClick';
import { StatusBottomBar } from '@components/interface/StatusBottomBar';
import { useInitElectronPrinters } from '@utils/hooks/useInitElectronPrinters';
import { useInitElectronVersion } from '@utils/hooks/useInitElectronVersion';
import { useChangeDeliveryStatus } from '@utils/hooks/useChangeDeliveryStatus';

const PrivateGlobalHooks = () => {
  useInitSubscriptionDetails();
  useIncomingOrder();
  useRiderAssigned();
  useChangeOrderStatus();
  useSimulateClick();
  useInitElectronPrinters();
  useInitElectronVersion();
  useChangeDeliveryStatus();

  return null;
};

const Layout: FC = () => {
  const isDesktop = useDesktop();
  const { commerce } = useGetCommerce();
  const { hasModule } = useModules();
  const { isFreemium, isTrial, accountId } = useSubscription();

  return (
    <WebSocketProvider
      url={WEBSOCKET_URL}
      accountId={accountId}
      commerceId={commerce?.id}
      allowConnection={hasModule(ModuleName.HISTORIAL) || false}
    >
      <NotificationProvider>
        <PrivateGlobalHooks />
        <main className="w-screen h-screen flex flex-col">
          <Header />
          <div className="flex flex-1 flex-col-reverse lg:flex-row overflow-hidden pt-14 pb-[62.14px] lg:pb-0">
            <NavBar />

            {(isFreemium || isTrial) && !isDesktop && <SubscriptionStatusBar isMobile />}
            {!isDesktop && <StatusBottomBar />}
            <Outlet />
          </div>
          {isDesktop && <StatusBottomBar />}
          {(isFreemium || isTrial) && isDesktop && <SubscriptionStatusBar />}
        </main>
        <NotificationCenter />
      </NotificationProvider>
    </WebSocketProvider>
  );
};

export default Layout;
