import SelectDropDown from '@components/interface/SelectDropDown';
import PreferenceItem from '../components/PreferenceItem';
import PreferenceSection from '../components/PreferenceSection';
import ButtonAction from '@components/interface/ButtonAction';
import { Checkbox } from '@components/Forms';
import { PreferenceLabel } from '../components/Label';
import { useElectronPrintersFromStore } from '@store/actions/electronPrinters';
import { PRINT_AUTO_OPTIONS_MAP, PRINT_TEXT_SIZE_MAP } from '@utils/constants';
import {
  useElectronPrintSettingsActions,
  useElectronPrintSettingsFromStore,
} from '@store/actions/electronPrintSettings';
import { useElectron } from '@utils/hooks/useElectron';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { buildPrintOptions } from '@utils/buildElectronPrintOptions';
import { buildElectronOrderTicket } from '@pages/Orders/utils/electronTicket/buildTicket';
import { useGetCommerce } from '@pages/Commerce/hooks/commerce';
import { MOCK_ORDER } from '@utils/mocks';
import InputWLabel from '@components/interface/InputWLabel';
import useModules from '@utils/hooks/useModules';
import ToolTipwithChildren from '@components/interface/ToolTipwithChildren';
import { Printers } from '../components/MultiPrinters';

type PrintProps = {
  disabled?: boolean;
  printerKey: keyof typeof Printers;
};

export const ElectronPrint = ({ disabled = false, printerKey }: PrintProps) => {
  const currentPrinterListing = Printers[printerKey];
  const printers = useElectronPrintersFromStore();
  const storeSettings = useElectronPrintSettingsFromStore();
  const {
    electronPrinterName,
    electronPrintLogo,
    electronPrintCommerceData,
    electronPrintClientNumber,
    electronPrintTextSize,
    electronRightMargin,
    electronAutoPrint,
    electronLeftMargin,
    electronPrintNumber,
    electronMultiPrintDelayBetweenPrints: printDelay,
  } = storeSettings[printerKey];

  const { updateElectronPrintSettings } = useElectronPrintSettingsActions();
  const { print } = useElectron();
  const currentPrinter = printers.find((printer) => printer.name === electronPrinterName);
  const currentTextSize = Object.keys(PRINT_TEXT_SIZE_MAP).find(
    (key) => PRINT_TEXT_SIZE_MAP[key as keyof typeof PRINT_TEXT_SIZE_MAP].key === electronPrintTextSize,
  );
  const { hasModule } = useModules();
  const currentAutoPrintOption = !hasModule('IMPRESION_AUTOMATICA')
    ? PRINT_AUTO_OPTIONS_MAP.disabled.key
    : Object.keys(PRINT_AUTO_OPTIONS_MAP).find(
        (key) => PRINT_AUTO_OPTIONS_MAP[key as keyof typeof PRINT_AUTO_OPTIONS_MAP].key === electronAutoPrint,
      );
  const { commerce } = useGetCommerce();
  const [isPrinting, setIsPrinting] = useState(false);

  const handlePrint = async (preview = false) => {
    try {
      setIsPrinting(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const options = buildPrintOptions({
        printerName: electronPrinterName,
        preview,
        marginRightInPX: electronRightMargin,
        marginLeftInPX: electronLeftMargin,
      });
      const { ticket } = buildElectronOrderTicket({
        order: MOCK_ORDER,
        commerceAddress: commerce.direccion || '',
        commerceLogo: commerce?.logotipo && /^https?:\/\//.test(commerce?.logotipo || '') ? commerce.logotipo : '',
        commerceName: commerce.nombre,
        textSize: electronPrintTextSize,
        showClientPhone: electronPrintClientNumber,
        showCommerceAddress: electronPrintCommerceData,
        showLogo: electronPrintLogo && Boolean(commerce?.logotipo) && /^https?:\/\//.test(commerce?.logotipo || ''),
      });

      print(ticket, options);
    } catch (e) {
      if (e?.toString().includes('Printer name is required')) {
        toast.error('Selecciona una impresora');
        return;
      }

      toast.error(e?.toString() || 'No se pudo imprimir');
    } finally {
      setIsPrinting(false);
    }
  };

  return (
    <PreferenceSection icon="typcn:printer" title={currentPrinterListing.label}>
      <PreferenceItem>
        <div className="flex gap-2 sm:flex-row flex-col">
          <div className="sm:w-[200px]">
            <PreferenceLabel label="Impresora:" />
            <SelectDropDown
              options={printers.map((printer) => {
                return {
                  label: `${printer.label.slice(0, 20)}${printer.label.length > 20 ? '...' : ''}`,
                  value: printer.name,
                };
              })}
              disabled={printers.length === 0 || disabled}
              values={
                currentPrinter
                  ? {
                      label: currentPrinter.label,
                      value: currentPrinter.name,
                    }
                  : null
              }
              placeHolder="Impresora"
              onChange={({ value }) => {
                updateElectronPrintSettings({ electronPrinterName: value }, printerKey);
              }}
            />
          </div>
          <div className="sm:w-[150px]">
            <PreferenceLabel label="Texto:" />
            <SelectDropDown
              disabled={disabled}
              options={Object.keys(PRINT_TEXT_SIZE_MAP).map((key) => {
                const label = PRINT_TEXT_SIZE_MAP[key as keyof typeof PRINT_TEXT_SIZE_MAP].label;
                return {
                  label,
                  value: key,
                };
              })}
              values={
                currentTextSize
                  ? {
                      label: PRINT_TEXT_SIZE_MAP[currentTextSize as keyof typeof PRINT_TEXT_SIZE_MAP].label,
                      value: currentTextSize,
                    }
                  : null
              }
              placeHolder="Tamaño"
              onChange={({ value }) =>
                updateElectronPrintSettings(
                  { electronPrintTextSize: value as keyof typeof PRINT_TEXT_SIZE_MAP },
                  printerKey,
                )
              }
            />
          </div>
          <div className="sm:w-[190px]">
            <ToolTipwithChildren
              textTooltip="Activa para imprimir automáticamente"
              hidden={hasModule('IMPRESION_AUTOMATICA') || false}
            >
              <>
                <PreferenceLabel label="Impresión automática:" />
                <SelectDropDown
                  disabled={disabled || !hasModule('IMPRESION_AUTOMATICA')}
                  options={Object.keys(PRINT_AUTO_OPTIONS_MAP).map((key) => {
                    const label = PRINT_AUTO_OPTIONS_MAP[key as keyof typeof PRINT_AUTO_OPTIONS_MAP].label;
                    return {
                      label,
                      value: key,
                    };
                  })}
                  values={
                    currentAutoPrintOption
                      ? {
                          label:
                            PRINT_AUTO_OPTIONS_MAP[currentAutoPrintOption as keyof typeof PRINT_AUTO_OPTIONS_MAP].label,
                          value: currentAutoPrintOption,
                        }
                      : null
                  }
                  placeHolder="Selecciona"
                  onChange={({ value }) =>
                    updateElectronPrintSettings(
                      { electronAutoPrint: value as keyof typeof PRINT_AUTO_OPTIONS_MAP },
                      printerKey,
                    )
                  }
                />
              </>
            </ToolTipwithChildren>
          </div>
        </div>
      </PreferenceItem>
      <PreferenceItem>
        <div className="flex gap-2 sm:flex-row flex-col">
          <div className="sm:[&>div]:w-[275px]">
            <PreferenceLabel label="Margen izquierdo:" />
            <InputWLabel
              type="number"
              disabled={disabled}
              value={electronLeftMargin}
              postValue="px"
              onChange={(value) => {
                updateElectronPrintSettings(
                  {
                    electronLeftMargin: parseFloat(value),
                  },
                  printerKey,
                );
              }}
            />
          </div>
          <div className="sm:[&>div]:w-[275px]">
            <PreferenceLabel label="Margen derecho:" />
            <InputWLabel
              type="number"
              disabled={disabled}
              value={electronRightMargin}
              postValue="px"
              onChange={(value) => {
                updateElectronPrintSettings(
                  {
                    electronRightMargin: parseFloat(value),
                  },
                  printerKey,
                );
              }}
            />
          </div>
        </div>
      </PreferenceItem>
      <PreferenceItem>
        <div className="flex gap-2 sm:flex-row flex-col">
          <div className="sm:[&>div]:w-[275px]">
            <PreferenceLabel label="Cantidad de impresiones" />
            <InputWLabel
              type="number"
              disabled={disabled}
              value={electronPrintNumber}
              postValue={`ticket${electronPrintNumber === 1 ? '' : 's'}`}
              onChange={(value) => {
                updateElectronPrintSettings(
                  {
                    electronPrintNumber: parseFloat(value),
                  },
                  printerKey,
                );
              }}
            />
          </div>
          <div className="sm:[&>div]:w-[275px]">
            <PreferenceLabel label="Espacio entre impresiones" />
            <InputWLabel
              type="number"
              disabled={disabled}
              value={printDelay}
              postValue={`segundo${printDelay === 1 ? '' : 's'}`}
              onChange={(value) => {
                updateElectronPrintSettings(
                  {
                    electronMultiPrintDelayBetweenPrints: parseFloat(value),
                  },
                  printerKey,
                );
              }}
            />
          </div>
        </div>
      </PreferenceItem>
      <PreferenceItem>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 bg-color-gray p-3 rounded-md">
          <Checkbox
            label="Incluir logotipo"
            disabled={disabled}
            checked={electronPrintLogo}
            onChange={(val) => updateElectronPrintSettings({ electronPrintLogo: val }, printerKey)}
          />
          <Checkbox
            disabled={disabled}
            label="Incluir dirección del comercio"
            checked={electronPrintCommerceData}
            onChange={(val) => updateElectronPrintSettings({ electronPrintCommerceData: val }, printerKey)}
          />
          <Checkbox
            disabled={disabled}
            label="Incluir teléfono del cliente"
            checked={electronPrintClientNumber}
            onChange={(val) => updateElectronPrintSettings({ electronPrintClientNumber: val }, printerKey)}
          />
        </div>
      </PreferenceItem>
      <PreferenceItem>
        <div className="mt-2 flex [&>button]:flex-1 gap-4">
          <ButtonAction
            colorBg="color-white"
            colorTxt="color-secondary"
            icon="dashicons:arrow-right-alt2"
            textButton="VISTA PREVIA"
            hasBorder
            disable={disabled || isPrinting}
            onClick={() => handlePrint(true)}
          />
          <ButtonAction
            colorBg="color-secondary"
            colorTxt="color-white"
            icon="dashicons:arrow-right-alt2"
            textButton="PROBAR"
            disable={disabled || isPrinting}
            onClick={() => handlePrint()}
          />
        </div>
      </PreferenceItem>
    </PreferenceSection>
  );
};
