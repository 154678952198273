/* eslint-disable no-console */
import { useState, createContext, useContext, useEffect, ReactNode } from 'react';
import { clearCache } from '@services/cache';
import { usePostHog } from 'posthog-js/react';
import useStoredState from '@utils/hooks/useStoredState';
import { EMPTY_VALUE, TELEMETRY_STORAGE_KEY } from '@utils/constants';
import { resetStore } from '@store/index';

interface loginProps {
  id_commerce: string;
  id_user: string;
  id_path: string;
  commerce_name: string;
  commerce_logo: string;
  user_name: string;
  token: string;
  user: {
    email: string;
    username: string;
    name: string;
    status: string;
  };
  onboarding_finish_date: string | null;
}

interface AuthContextProps {
  isAuthenticated: boolean;
  isReady: boolean;
  commerceName: string;
  onboardingDate: loginProps['onboarding_finish_date'];
  setCommerceName: (param: string) => void;
  commerceLogo: string;
  setCommerceLogo: (param: string) => void;
  commercePath: string;
  setCommercePath: (param: string) => void;
  userId: string;
  userName: string;
  login: (params: loginProps) => void;
  logout: () => void;
  refreshLogin: (userToken: string, commerceId: string) => void;
  checkAuth: () => void;
  user: {
    email: string;
    name: string;
    username: string;
    status: string;
  };
}

const AuthContext = createContext<AuthContextProps>({
  isAuthenticated: false,
  isReady: true,
  commerceName: '',
  onboardingDate: '',
  setCommerceName: () => {
    window.console.log('Empty method setCommerceName');
  },
  commerceLogo: '',
  setCommerceLogo: () => {
    window.console.log('Empty method setCommerceLogo');
  },
  commercePath: '',
  setCommercePath: () => {
    window.console.log('Empty method setCommercePath');
  },
  userId: '',
  userName: '',
  refreshLogin: () => {
    window.console.log('Empty method refreshLogin');
  },
  login: () => {
    window.console.log('Empty method login');
  },
  logout: () => {
    window.console.log('Empty method logout');
  },
  checkAuth: () => {
    window.console.log('Empty method checkAuth');
  },
  user: {
    email: '',
    username: '',
    name: '',
    status: '',
  },
});

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthComponent = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [commerceName, setCommerceName] = useState('');
  const [commerceLogo, setCommerceLogo] = useState('');
  const [commercePath, setCommercePath] = useState('');
  const [userId, setUserId] = useState('');
  const [userName, setUserName] = useState('');
  const [isReady, setIsReady] = useState(false);
  const [user, setUser] = useState({ user: { email: '', name: '', username: '', status: '' } });
  const [telemetrySessionId, setTelemetrySessionId] = useStoredState(TELEMETRY_STORAGE_KEY, EMPTY_VALUE);
  const [onboardingDate, setOnboardingDate] = useState<string | null>('');

  const posthog = usePostHog();

  const checkAuth = () => {
    setIsReady(false);
    const id_commerce = localStorage.getItem('id_commerce');
    const id_user = localStorage.getItem('id_user');
    const id_path = localStorage.getItem('id_path');
    const commerce_name = (localStorage.getItem('commerce_name') || '').toString();
    const commerce_logo = (localStorage.getItem('commerce_logo') || '').toString();
    const user_name = (localStorage.getItem('user_name') || '').toString();
    const token = localStorage.getItem('token');
    const userData = localStorage.getItem('userData');
    const onBoardingDate = localStorage.getItem('onboarding_finish_date');

    if (
      id_commerce &&
      id_user &&
      id_path &&
      commerce_name &&
      user_name &&
      typeof commerce_logo === 'string' &&
      token &&
      userData
    ) {
      setCommerceName(commerce_name);
      setCommerceLogo(commerce_logo);
      setCommercePath(id_path);
      setUserId(id_user);
      setUserName(user_name);
      setIsAuthenticated(true);
      setUser({ user: JSON.parse(userData) });
      setOnboardingDate(onBoardingDate);
    }
    setIsReady(true);

    if (!telemetrySessionId) {
      posthog?.identify(commerceName, {
        id_commerce: id_commerce,
        id_user: userId,
        id_path: id_path,
        user_name: user_name,
        commerce_name: commerce_name,
        user: {
          email: user.user.email,
          name: user.user.name,
          username: user.user.username,
          status: user.user.status,
        },
      });
      const newSessionId = posthog?.get_session_id();
      setTelemetrySessionId(newSessionId);
    }
  };

  const login = (params: loginProps) => {
    localStorage.setItem('id_commerce', params.id_commerce);
    localStorage.setItem('id_user', params.id_user);
    localStorage.setItem('id_path', params.id_path);
    localStorage.setItem('commerce_name', params.commerce_name);
    localStorage.setItem('commerce_logo', params.commerce_logo);
    localStorage.setItem('user_name', params.user_name);
    localStorage.setItem('token', params.token);
    localStorage.setItem(
      'userData',
      JSON.stringify({
        email: params.user.email,
        name: params.user.name,
        username: params.user.username,
        status: params.user.status,
      }),
    );
    if (params.onboarding_finish_date) localStorage.setItem('onboarding_finish_date', params.onboarding_finish_date);

    setCommerceName(params.commerce_name);
    setCommerceLogo(params.commerce_logo);
    setCommercePath(params.id_path);
    setUserId(params.id_user);
    setUserName(params.user_name);
    setIsAuthenticated(true);
    setUser({
      user: {
        email: params.user.email,
        name: params.user.name,
        username: params.user.username,
        status: params.user.status,
      },
    });
    setOnboardingDate(params.onboarding_finish_date);

    if (!telemetrySessionId) {
      posthog?.identify(commerceName, {
        id_commerce: params.id_commerce,
        id_user: userId,
        id_path: params.id_path,
        user_name: params.user_name,
        commerce_name: params.commerce_name,
        user: {
          email: user.user.email,
          name: user.user.name,
          username: user.user.username,
          status: user.user.status,
        },
      });
      const newSessionId = posthog?.get_session_id();
      setTelemetrySessionId(newSessionId);
    }
  };

  const logout = () => {
    resetStore();
    clearCache();
    [
      'id_commerce',
      'id_user',
      'id_path',
      'commerce_name',
      'commerce_logo',
      'user_name',
      'token',
      'userData',
      'onboarding_finish_date',
    ].forEach((item) => localStorage.removeItem(item));
    setIsAuthenticated(false);
    setTelemetrySessionId('');
  };

  const refreshLogin = (userToken: string, commerceId: string) => {
    setIsReady(false);
    resetStore();
    clearCache();
    localStorage.setItem('token', userToken);
    localStorage.setItem('id_commerce', commerceId);
    requestAnimationFrame(() => setIsReady(true));
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        onboardingDate,
        isReady,
        login,
        logout,
        refreshLogin,
        checkAuth,
        commerceName,
        setCommerceName,
        commerceLogo,
        setCommerceLogo,
        commercePath,
        setCommercePath,
        userId,
        userName,
        user: {
          email: user.user.email,
          name: user.user.name,
          username: user.user.username,
          status: user.user.status,
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthComponent, useAuth };
