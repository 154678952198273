import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Integration } from '@utils/constants';

const initialState: Integration[] = [];

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    initIntegrations: (_, action: PayloadAction<Integration[]>) => {
      return action.payload;
    },
    addIntegration: (state, action: PayloadAction<Integration>) => {
      return [...state, action.payload];
    },
    removeIntegration: (state, action: PayloadAction<Integration>) => {
      return state.filter((integration) => integration.id !== action.payload.id);
    },
    reset: () => initialState,
  },
});
