import { PosPrintOptions } from '@plick/electron-pos-printer';

type Props = {
  printerName: string | null | undefined;
  preview?: boolean;
  marginRightInPX?: number;
  marginLeftInPX?: number;
};

export const buildPrintOptions = ({ printerName, preview = false, marginRightInPX, marginLeftInPX }: Props) => {
  if (!printerName) {
    throw new Error('Printer name is required');
  }

  const options: PosPrintOptions = {
    preview,
    margin: `0 0 0 ${marginLeftInPX}px`,
    copies: 1,
    printerName,
    timeOutPerLine: 5000,
    silent: true,
    boolean: ['bold'],
    width: marginRightInPX ? `calc(100% - ${marginRightInPX}px)` : undefined,
    pageSize: { height: 301000, width: preview ? 205 : 71000 },
  };

  return options;
};
