import { preferencesSlice } from '../slices/preferences';
import { useAppDispatch, useAppSelector } from '../';
import { ALARM_SOUNDS_MAP } from '@utils/sounds';

export const usePreferencesFromStore = () => {
  return useAppSelector((state) => state.preferences);
};

export const usePreferencesActions = () => {
  const dispatch = useAppDispatch();

  const setAudio = (audio: keyof typeof ALARM_SOUNDS_MAP) => {
    localStorage.setItem('selectedSound', audio);
    dispatch(preferencesSlice.actions.setAudio(audio));
  };

  return { setAudio };
};
