import { Tabler } from '@plick/ui';

type OrdersCounterProps = {
  isDesktop: boolean;
  current: number;
  limit: number;
  usagePercent: number;
};

export const OrdersCounter = ({ isDesktop, current, usagePercent: percent }: OrdersCounterProps) => {
  const color = percent > 75 ? '#FF3939' : percent > 50 ? '#FD9900' : '#04AF00';

  return (
    <section className="bg-color-white text-color-primary_dark px-[10px] py-[6px] rounded-[6px]">
      <div className="flex items center gap-1">
        <span className="flex items-center">
          <Tabler.IconPaperBag width={24} height={24} />
        </span>
        <div>
          <div className="flex">
            <p style={{ fontSize: isDesktop ? '16px' : '12px' }}>
              Pedidos en el mes:
              <span className="font-semibold"> {current.toLocaleString('es-MX')}</span>
            </p>
          </div>
          <div className="relative mt-[2px]">
            <div
              className="h-[3px] rounded-[3px] absolute"
              style={{ backgroundColor: color, width: percent + '%' }}
            ></div>
            <div className="bg-color-gray-light w-full h-[3px] rounded-[3px]"></div>
          </div>
        </div>
      </div>
    </section>
  );
};
