/* global URL_API_REST */
import axios from 'axios';

const instance = axios.create({
  baseURL: URL_API_REST,
  timeout: 30 * 1000,
  headers: {},
});

instance.interceptors.request.use(
  function (config) {
    const id_commerce = localStorage.getItem('id_commerce');
    const token = localStorage.getItem('token');
    if (id_commerce && token && config.headers) {
      (config.headers as unknown as { id_comercio: string }).id_comercio = id_commerce;
      if (!config.headers.Authorization)
        (config.headers as unknown as { Authorization: string }).Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default instance;
