import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import notificationSound from '@assets/audio/notification.mp3';
import { ALARM_SOUNDS_MAP } from '@utils/sounds';

const initialState = {
  audio: localStorage.getItem('selectedSound') || notificationSound,
};

export const preferencesSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    setAudio: (state, action: PayloadAction<keyof typeof ALARM_SOUNDS_MAP>) => {
      return { ...state, audio: action.payload };
    },
    reset: () => initialState,
  },
});
