import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Order } from '@interfaces/OrderTypes';

const initialState: Order[] = [];

type OrderId = Pick<Order, 'id'>;

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    initOrders: (_, action: PayloadAction<Order[]>) => {
      return [...action.payload];
    },
    addOrder: (state, action: PayloadAction<Order>) => {
      return [action.payload, ...state]; // new orders are added at the beginning of the list
    },
    updateOrder: (state, action: PayloadAction<Order>) => {
      return state.map((order) => {
        if (order.id === action.payload.id) {
          return action.payload;
        }
        return order;
      });
    },
    deleteOrder: (state, action: PayloadAction<OrderId>) => {
      return state.filter((order) => order.id !== action.payload.id);
    },
    reset: () => initialState,
  },
});
