/* global REACT_APP_PUBLIC_FEATURE_FLAGS */

export const useGetFeatureFlags = () => {
  const featureFlags = REACT_APP_PUBLIC_FEATURE_FLAGS;
  return featureFlags;
};

export const hasFeatureFlag = (flag: string) => {
  const featureFlags = useGetFeatureFlags();

  return featureFlags.includes(flag);
};
