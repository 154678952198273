import { OrderDetail } from '@interfaces/OrderTypes';

export const MOCK_ORDER: OrderDetail = {
  pedido: {
    id: '0000',
    folio: 'Ejemplo',
    id_comercio: 'test',
    metodo_entrega: 'delivery',
    metodo_pago: 'efectivo',
    link_clip: null,
    numero_celular: '5566778899',
    colonia: 'Lomas de Chapultepec',
    navegador:
      'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) plick-desktop/0.1.0 Chrome/112.0.5615.204 Electron/24.8.8 Safari/537.36',
    propina: 0,
    costo_envio: 0,
    subtotal: 0,
    total: 0,
    comentario: 'muchas gracias!',
    created_at: '2023-11-29T23:01:28.746Z',
    updated_at: '2023-11-29T23:29:20.562Z',
    nombre: 'Cliente de prueba',
    estatus_pedido: 'activo',
    estatus: 'on_the_way',
    estatus_pago: undefined,
    enlace_pago: undefined,
    calle: '2 de Abril',
    numero_exterior: '105',
    numero_interior: 'A',
    referencias: 'entre 5 de Mayo y 16 de Septiembre',
    numero_mesa: undefined,
    cliente_paga_con: '1920.00',
    direccion_completa: '',
    country_code: 52,
    delivery_driver_info: undefined,
    delivery_tracking_id: undefined,
    delivery_tracking_url: undefined,
    delivery_type: 'gratis',
    coupon_code: '',
    coupon_type: undefined,
    coupon_value: undefined,
    coupon_amount: undefined,
    has_requested_rider: false,
  },
  productos: [
    {
      id: '2764',
      id_pedido: 7798,
      id_producto: '3751de6d7e4c8f85d66ed4dc1e4d0ed4',
      id_categoria: '42300c40e0cc228c360fde1838692b17',
      presentacion: 'Orden',
      nombre: 'Papas rejilla',
      cantidad: 2,
      precio: 69,
      subtotal: 0,
      created_at: '2023-11-29T23:01:28.746Z',
      updated_at: '2023-11-29T23:01:28.746Z',
      instrucciones: 'Sin sal por favor',
      extras: [],
    },
    {
      id: '2765',
      id_pedido: 7798,
      id_producto: 'ffbe61c7f2e11f5707c709246cc5d985',
      id_categoria: 'd19eb2bc25771bb8e005089aa3604ff1',
      presentacion: 'Basica',
      nombre: 'Jalapeño Poppers',
      cantidad: 1,
      precio: 179,
      subtotal: 0,
      created_at: '2023-11-29T23:01:28.746Z',
      updated_at: '2023-11-29T23:01:28.746Z',
      instrucciones: '',
      extras: [
        {
          id: '2196',
          id_pedido_producto: 2765,
          id_extra: 'a5057149cec421bd9e08e1dd2c1fc5d8',
          nombre: 'Con lemon pepper',
          cantidad: 1,
          precio: 0,
          subtotal: 0,
          created_at: '2023-11-29T23:01:28.746Z',
          updated_at: '2023-11-29T23:01:28.746Z',
        },
        {
          id: '2198',
          id_pedido_producto: 2765,
          id_extra: '506dd3cd82227d80c8ff3e677ff9db9a',
          nombre: 'Aguacate+',
          cantidad: 1,
          precio: 0,
          subtotal: 0,
          created_at: '2023-11-29T23:01:28.746Z',
          updated_at: '2023-11-29T23:01:28.746Z',
        },
      ],
    },
    {
      id: '2766',
      id_pedido: 7798,
      id_producto: 'a83947ca697b1f00c0f3d95b7fb52d24',
      id_categoria: 'd19eb2bc25771bb8e005089aa3604ff1',
      presentacion: 'Doble',
      nombre: 'Aguacate Lover',
      cantidad: 2,
      precio: 0,
      subtotal: 0,
      created_at: '2023-11-29T23:01:28.746Z',
      updated_at: '2023-11-29T23:01:28.746Z',
      instrucciones: 'sin mostaza por favor, alergias',
      extras: [
        {
          id: '2201',
          id_pedido_producto: 2766,
          id_extra: '5a3fb0a18e96e2174e7ea7e315e68e45',
          nombre: 'Chorizo+',
          cantidad: 2,
          precio: 0,
          subtotal: 0,
          created_at: '2023-11-29T23:01:28.746Z',
          updated_at: '2023-11-29T23:01:28.746Z',
        },
      ],
    },
    {
      id: '2767',
      id_pedido: 7798,
      id_producto: '6174dd98c3f256552b07813bba6a43f3',
      id_categoria: '5945251748be602e49fef7126f3b77d4',
      presentacion: '345ml',
      nombre: 'Boing Mango',
      cantidad: 1,
      precio: 0,
      subtotal: 0,
      created_at: '2023-11-29T23:01:28.746Z',
      updated_at: '2023-11-29T23:01:28.746Z',
      extras: [],
      instrucciones: '',
    },
  ],
};
