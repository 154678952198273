import type { FC } from 'react';
import NavBarButton from './components/interface/NavBarButton';
import BtnMenu from '@assets/images/iconos-aside-panel-menu.svg';
import BtnORders from '@assets/images/iconos-aside-panel-orders.svg';
import BtnPubli from '@assets/images/iconos-aside-panel-anuncio.svg';
import IconoEstadisticas from '@assets/images/iconos-aside-panel-estadisticas.svg';
import useModules from '@utils/hooks/useModules';
import { ModuleName } from '@utils/constants';
import { useSubscription } from '@pages/Subscriptionsv2/hooks/useSubscriptions';

const NavBarMobile: FC = () => {
  const { isFreemium } = useSubscription();
  const { hasModule } = useModules();

  return (
    <div className="fixed w-full bottom-0 left-0 bg-color-gray_light p-0 pt-[2px] flex-1 z-40">
      <div className="flex w-full justify-evenly pb-2 lg:flex-col">
        {(isFreemium || hasModule(ModuleName.ESTADISTICAS)) && (
          <NavBarButton
            isExpanded
            to="estadisticas"
            id="estadisticas-link"
            icon={IconoEstadisticas}
            label="Inicio"
            notIncludedOnCurrentPlan={!hasModule(ModuleName.ESTADISTICAS)}
          />
        )}
        {(isFreemium || hasModule(ModuleName.MENU)) && (
          <NavBarButton
            isExpanded
            to="menu"
            id="menu-link"
            icon={BtnMenu}
            label="Menú"
            notIncludedOnCurrentPlan={!hasModule(ModuleName.MENU)}
          />
        )}
        {(isFreemium || hasModule(ModuleName.HISTORIAL)) && (
          <NavBarButton
            isExpanded
            to="control-pedidos"
            id="control-pedidos-link"
            icon={BtnORders}
            label={'Pedidos'}
            notIncludedOnCurrentPlan={!hasModule(ModuleName.HISTORIAL)}
          />
        )}
        {(isFreemium || hasModule(ModuleName.ANUNCIOS)) && (
          <NavBarButton
            isExpanded
            to="anuncios"
            id="anuncios-link"
            icon={BtnPubli}
            label={'Anuncios'}
            notIncludedOnCurrentPlan={!hasModule(ModuleName.ANUNCIOS)}
          />
        )}
      </div>
    </div>
  );
};

export default NavBarMobile;
