import { Subscription } from '@interfaces/general';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: Subscription = {
  planId: null,
  trialDaysRemaining: 0,
  modulesAndPermissions: [],
  trialUsed: false,
  accountId: '',
  orderLimits: {
    currentMonthCount: undefined,
    monthlyLimit: undefined,
    quotaUsagePercent: undefined,
  },
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    initSubscription: (_, action: PayloadAction<Subscription>) => {
      return action.payload;
    },
    reset: () => initialState,
  },
});
