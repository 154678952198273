import type { FC } from 'react';

type CheckboxProps = {
  id?: string;
  name?: string;
  disabled?: boolean;
  checked: boolean;
  label?: string;
  onChange: (checked: boolean) => void;
};

const Checkbox: FC<CheckboxProps> = ({ name, id, disabled, checked = false, onChange, label }) => {
  return (
    <div className="flex items-center">
      <input
        className="focus:outline-none mr-2 w-5 h-5 accent-color-secondary hover:cursor-pointer"
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={(e) => onChange(Boolean(e.target.checked))}
        disabled={disabled}
      />
      <label className="text-color-primary">
        <span className="">{label}</span>
      </label>
    </div>
  );
};

export default Checkbox;
