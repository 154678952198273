import type { FC } from 'react';
import Loading from '@components/LoadingProcess';

const LoadingPage: FC = () => {
  return (
    <div className="flex w-[100vw] h-[100vh] justify-center items-center">
      <Loading />
    </div>
  );
};

export default LoadingPage;
